import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/css/OverlayScrollbars.css";

export default function MainContainer({ children, className }) {
  return (
    <div
      className={
        "flex flex-col lg:flex-row min-h-screen lg:h-screen overflow-hidden " +
        className
      }
    >
      {children}
    </div>
  );
}

export const ColumnOne = ({ children, className }) => {
  return (
    <div className={"flex-initial lg:flex-1 overflow-hidden " + className}>
      <div className="flex flex-col h-full">
        <OverlayScrollbarsComponent
          className="flex-1 overlay-y-auto"
          options={{ scrollbars: { autoHide: "scroll" } }}
        >
          <div className="p-5 lg:p-8">{children}</div>
        </OverlayScrollbarsComponent>
      </div>
    </div>
  );
};

export const ColumnTwo = ({ children, className, after }) => {
  return (
    <div
      className={
        "w-auto lg:w-1/3 border-l-0 lg:border-l border-t lg:border-t-0 border-white-400 dark:border-black-100 flex flex-col " +
        className
      }
    >
      <OverlayScrollbarsComponent
        options={{ scrollbars: { autoHide: "scroll" } }}
        className="flex-1 overflow-y-auto"
      >
        <div className="p-5 lg:p-8">{children}</div>
      </OverlayScrollbarsComponent>
      {after}
    </div>
  );
};
