import { configureStore } from "@reduxjs/toolkit";
import interfaceReducer from "./reducers/interface";
import cartReducer from "./reducers/cart";

export default configureStore({
  reducer: {
    interface: interfaceReducer,
    cart: cartReducer,
  },
});
