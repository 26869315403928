import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Header from "../../components/Header";
import { ColumnOne } from "../../components/MainContainer";
import ProductList from "../../components/ProductList";
import service from "../../service";
import NotFound from "../../components/NotFound";

export default function Ecommerce() {
  const [data, setData] = useState([{}, {}, {}, {}]);
  const _getData = (page = 1, reset = true) => {
    service
      .get("/product", {
        params: {
          page,
        },
      })
      .then((response) => {
        const { data } = response.data;
        if (reset) {
          setData(data);
        } else {
          setData((value) => value.concat(data));
        }
      })
      .catch((e) => {
        if (reset) {
          setData([]);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    _getData();
  }, []);
  return (
    <ColumnOne>
      <Helmet>
        <title>Ecommerce</title>
      </Helmet>
      <Header>Buku Pilihan</Header>
      {data.length > 0 ? (
        <div className="grid grid-flow-row grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5 lg:gap-8 mt-8 pb-8 box-border">
          {data.map((item, index) => (
            <ProductList
              title={item.title}
              price={item.price}
              id={item.id}
              cover={item.cover}
              key={`${index}`}
            />
          ))}
        </div>
      ) : (
        <NotFound />
      )}
    </ColumnOne>
  );
}
