import { Fragment, useRef } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import CheckoutItem from "../components/CheckoutItem";
import CourierList from "../components/CourierList";
import DropdownSelect from "../components/DropdownSelect";
import Header from "../components/Header";
import Loader from "../components/Loader";
import MainContainer, {
  ColumnOne,
  ColumnTwo,
} from "../components/MainContainer";
import TextInput from "../components/TextInput";
import service from "../service";
import Button from "../components/Button";
import { resetNotSync } from "../redux/reducers/cart";
import { setPath } from "../redux/reducers/interface";
import { useHistory } from "react-router-dom";

export default function Checkout() {
  const cart = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    control,
    watch,
    formState: { errors },
    register,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      destination_rajaongkir: "",
      destination_first: "",
      recipient_name: "",
      recipient_phone: "",
      shipping_service: "",
    },
  });
  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [subdistrict, setSubdistrict] = useState([]);
  const [provinceSelected, setProvinceSelected] = useState("");
  const [citySelected, setCitySelected] = useState("");
  const [couriers, setCouriers] = useState([]);
  const [courierSelected, setCourierSelected] = useState("");
  const [total, setTotal] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [withShipping, setWithShipping] = useState(false);
  const _loadRef = useRef();

  const getShippingCost = (destination) => {
    setCouriers([{}, {}, {}, {}]);
    service
      .get("/shipping/cost", {
        params: {
          destination,
        },
      })
      .then((response) => {
        const { data } = response;
        setCouriers(data);
      })
      .catch(() => {
        setCouriers([]);
      });
  };

  const loadProvince = () => {
    setProvince([]);
    setCity([]);
    setSubdistrict([]);
    service
      .get("/shipping/province")
      .then((response) => {
        const { data } = response;

        const province = data.map((item) => ({
          value: item.province_id,
          label: item.province,
        }));
        setProvince(province);
      })
      .catch((e) => {});
  };

  const loadCity = (id) => {
    setCity([]);
    setSubdistrict([]);
    service
      .get("/shipping/city", {
        params: {
          id,
        },
      })
      .then((response) => {
        const { data } = response;

        const city = data.map((item) => ({
          value: item.city_id,
          label: item.city_name,
        }));
        setCity(city);
      })
      .catch((e) => {});
  };
  const loadSubdistrict = (id) => {
    setSubdistrict([]);
    service
      .get("/shipping/subdistrict", {
        params: {
          id,
        },
      })
      .then((response) => {
        const { data } = response;

        const subdistrict = data.map((item) => ({
          value: item.subdistrict_id,
          label: item.subdistrict_name,
        }));
        setSubdistrict(subdistrict);
      })
      .catch((e) => {});
  };

  const proceedCheckout = ({
    destination_rajaongkir,
    destination_first,
    recipient_name,
    recipient_phone,
    shipping_service,
  }) => {
    _loadRef.current.show();
    service
      .post(
        "/checkout",
        withShipping
          ? {
              destination_rajaongkir,
              destination_first,
              recipient_name,
              recipient_phone,
              shipping_service,
            }
          : {}
      )
      .then((response) => {
        const { id } = response.data;
        dispatch(resetNotSync());
        history.replace({ pathname: `/payment/${id}` });
      })
      .catch((e) => {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadProvince();
  }, []);

  useEffect(() => {
    dispatch(setPath("/ecommerce"));
  }, [dispatch]);

  useEffect(() => {
    let shipping = false;
    let total = 0;
    for (let item of cart) {
      if (item.type === "product") {
        shipping = true;
      }
      total = total + item.price * item.qty;
    }

    setWithShipping(shipping);
    setTotal(total);
  }, [cart]);

  useEffect(() => {
    setValue("shipping_service", "");
    setShippingCost(0);
  }, [courierSelected]);

  if (!withShipping) {
    return (
      <div className="p-5 lg:p-8">
        <Helmet>
          <title>Checkout</title>
        </Helmet>
        <Header>Ringkasan</Header>
        <div className="mt-8">
          {cart.map((item, index) => (
            <CheckoutItem
              key={`${index}`}
              price={item.price}
              name={item.name}
              id={item.id}
              qty={item.qty}
              image={item.image}
              type={item.type}
            />
          ))}
        </div>
        <div className="mt-5 pt-5 mb-10">
          <div className="flex justify-between border-b border-dashed border-white-400 pb-3 mb-3">
            <span>Total</span>
            <NumberFormat
              value={total}
              prefix="Rp "
              displayType="text"
              thousandSeparator={true}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <Button
            type="button"
            className="w-full lg:w-1/3"
            onClick={() => {
              proceedCheckout({});
            }}
            disabled={_loadRef.current?.status() || false}
          >
            <Loader
              className="bg-black-100"
              placeholder="Proses Pembayaran"
              ref={_loadRef}
            />
          </Button>
        </div>
      </div>
    );
  }
  return (
    <MainContainer>
      <ColumnOne>
        <Helmet>
          <title>Checkout</title>
        </Helmet>
        <Header>Ringkasan</Header>
        <div className="mt-8">
          {cart.map((item, index) => (
            <CheckoutItem
              key={`${index}`}
              price={item.price}
              name={item.name}
              id={item.id}
              qty={item.qty}
              image={item.image}
              type={item.type}
            />
          ))}
        </div>
        <div className="mt-5 pt-5">
          <div className="flex justify-between border-b border-dashed border-white-400 pb-3 mb-3">
            <span>Total</span>
            <NumberFormat
              value={total}
              prefix="Rp "
              displayType="text"
              thousandSeparator={true}
            />
          </div>
          <div className="flex justify-between border-b border-dashed border-white-400 pb-3 mb-3">
            <span>Ongkos Kirim</span>
            <NumberFormat
              value={shippingCost}
              prefix="Rp "
              displayType="text"
              thousandSeparator={true}
            />
          </div>
          <div className="flex justify-between pb-3 mb-3">
            <NumberFormat
              className="ml-auto font-bold"
              value={shippingCost + total}
              prefix="Rp "
              displayType="text"
              thousandSeparator={true}
            />
          </div>
        </div>
      </ColumnOne>
      <ColumnTwo>
        <form onSubmit={handleSubmit(proceedCheckout)}>
          <h2 className="poppins font-bold mb-5">Alamat</h2>
          <div className="bg-white-100 rounded-sm mb-8">
            <div className="p-5">
              <TextInput
                containerClassName="mb-4"
                label="Nama Penerima"
                type="text"
                message={errors.recipient_name?.message}
                {...register("recipient_name", { required: "Harap diisi" })}
              />
              <TextInput
                containerClassName="mb-4"
                label="No. Handphone"
                placeholder="Contoh: 08127176xxxx"
                type="text"
                message={errors.recipient_phone?.message}
                {...register("recipient_phone", { required: "Harap diisi" })}
              />
              <DropdownSelect
                containerClassName="mb-4"
                label="Provinsi"
                loading={province.length < 1}
                options={province}
                onChange={(id) => {
                  setProvinceSelected(id);
                  if (id) loadCity(id);
                }}
              />
              <DropdownSelect
                containerClassName="mb-4"
                label="Kota/Kabupaten"
                disabled={!provinceSelected}
                loading={city.length < 1}
                options={city}
                onChange={(id) => {
                  setCitySelected(id);
                  if (id) loadSubdistrict(id);
                }}
              />
              <Controller
                control={control}
                defaultValue=""
                rules={{ required: "Harap diisi" }}
                name="destination_rajaongkir"
                render={({ field: { value, onChange } }) => (
                  <DropdownSelect
                    containerClassName="mb-4"
                    label="Kecamatan"
                    disabled={!citySelected}
                    loading={subdistrict.length < 1}
                    options={subdistrict}
                    value={value}
                    message={errors.destination_rajaongkir?.message}
                    onChange={(id) => {
                      onChange(id);
                      setValue("shipping_service", "");
                      setShippingCost(0);
                      if (id) getShippingCost(id);
                    }}
                  />
                )}
              />
              <TextInput
                containerClassName="mb-4"
                label="Alamat"
                placeholder="Nama jalan, gedung, kelurahan"
                type="text"
                message={errors.destination_first?.message}
                {...register("destination_first", { required: "Harap diisi" })}
              />
            </div>
          </div>
          {watch("destination_rajaongkir") ? (
            <Controller
              control={control}
              name="shipping_service"
              rules={{ required: "Harap diisi" }}
              render={({ field: { value, onChange } }) => (
                <Fragment>
                  <h2 className="poppins font-bold mb-5">Jasa Pengiriman</h2>
                  {errors.shipping_service?.message ? (
                    <div className="text-red-100 text-sm roboto mb-5 -mt-5">
                      {errors.shipping_service?.message}
                    </div>
                  ) : null}
                  {couriers.map((item, index) => (
                    <CourierList
                      name={item.name}
                      code={item.code}
                      costs={item.costs}
                      key={`${index}`}
                      value={value}
                      selected={courierSelected === item.code}
                      onChangeCourier={setCourierSelected}
                      onChangeService={(service, cost) => {
                        onChange(`${item.code} - ${service}`);
                        setShippingCost(cost);
                      }}
                    />
                  ))}
                </Fragment>
              )}
            />
          ) : null}
          <div className="flex flex-col">
            <Button
              type="submit"
              disabled={_loadRef.current?.status() || false}
            >
              <Loader
                className="bg-black-100"
                placeholder="Proses Pembayaran"
                ref={_loadRef}
              />
            </Button>
          </div>
        </form>
      </ColumnTwo>
    </MainContainer>
  );
}
