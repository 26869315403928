import { useEffect, useState } from "react";
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { increaseQty, decreaseQty, resetQty } from "../redux/reducers/cart";

export default function CartItem({ name, price, id, qty, index, image, type }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(qty);
  useEffect(() => {
    setValue(qty);
  }, [qty]);
  return (
    <div className="p-5 rounded-sm text-left hover:shadow-sm mb-3 bg-white-100 dark:bg-dark-100 w-full block">
      <Link
        to={type === "product" ? `/ecommerce/product/${id}` : `/course/${id}`}
        className="flex"
      >
        <div className="w-16">
          <div className="w-1-1">
            <img
              src={process.env.REACT_APP_BASE_URL + image}
              className="object-cover"
              alt={name}
            />
          </div>
        </div>
        <div className="flex-1 ml-4">
          <h2 className="poppins font-bold nl-1">{name}</h2>
          <div className="text-sm mt-1">
            <NumberFormat
              value={price}
              prefix="Rp "
              displayType="text"
              thousandSeparator={true}
            />
          </div>
        </div>
        {type === "course" ? (
          <div>
            <button
              onClick={(e) => {
                e.preventDefault();
                dispatch(resetQty({ index, qty: 0 }));
              }}
              type="button"
              className="ml-2 p-2 w-10 h-10 rounded bg-red-100 text-white-100 flex justify-center items-center"
            >
              <FaTrash size={12} />
            </button>
          </div>
        ) : null}
      </Link>
      {type === "product" ? (
        <div className="mt-5 flex">
          <div className="flex w-32 rounded border border-black-300">
            <button
              onClick={() => {
                dispatch(decreaseQty({ index }));
              }}
              type="button"
              className="p-3 border-r border-black-300 rounded-none rounded-l"
            >
              <FaMinus size={10} />
            </button>
            <input
              type="number"
              className="w-full py-2 px-3 box-border text-center text-sm bg-white-100 dark:bg-dark-100"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onBlur={(e) => {
                const value = parseInt(e.target.value);
                dispatch(resetQty({ index, qty: value || 1 }));
              }}
            />
            <button
              onClick={() => {
                dispatch(increaseQty({ index }));
              }}
              type="button"
              className="p-3 border-l border-black-300 rounded-none rounded-r"
            >
              <FaPlus size={10} />
            </button>
          </div>
          <button
            onClick={() => {
              dispatch(resetQty({ index, qty: 0 }));
            }}
            type="button"
            className="ml-2 p-2 w-10 rounded bg-red-100 text-white-100 flex justify-center items-center"
          >
            <FaTrash size={12} />
          </button>
        </div>
      ) : null}
    </div>
  );
}
