import { useEffect, useState } from "react";

export default function DropdownSelect({
  label,
  className,
  containerClassName,
  message,
  options = [],
  loading,
  value,
  onChange = () => {},
  disabled = false,
}) {
  const [show, setShow] = useState(false);
  const [val, setVal] = useState(value);
  useEffect(() => {
    onChange("");
  }, [options]);
  return (
    <div className={containerClassName}>
      {label && (
        <label className="text-black-200 dark:text-white-500 mb-1 roboto block">
          {label}
        </label>
      )}
      <div
        className="relative"
        onFocus={() => {
          setShow(true);
        }}
        onBlur={(event) => {
          if (!event.currentTarget.contains(event.relatedTarget)) {
            setShow(false);
          }
        }}
      >
        <input
          disabled={disabled}
          type="text"
          value={options.find((el) => el.value === val)?.label || ""}
          readOnly={true}
          className={
            "h-10 bg-white-100 dark:bg-dark-100 border border-white-400 dark:border-black-200 rounded-none block w-full text-black-200 dark:text-white-500 roboto py-1 px-3 box-border " +
            className
          }
        />
        {show ? (
          <div
            className="absolute z-20 left-0 w-full h-64 bg-white-100 shadow-sm overflow-y-auto border border-white-200"
            style={{ top: "100%" }}
          >
            {loading ? (
              <div className="h-full w-full flex items-center justify-center text-center">
                Loading...
              </div>
            ) : (
              options.map((item, index) => (
                <button
                  className="px-5 py-3 border-b w-full text-left border-white-300"
                  key={`${index}`}
                  onClick={() => {
                    setVal(item.value);
                    onChange(item.value);
                    setShow(false);
                  }}
                >
                  {item.label}
                </button>
              ))
            )}
          </div>
        ) : null}
      </div>
      {message && <div className="text-red-100 text-sm roboto">{message}</div>}
    </div>
  );
}
