import { FaChevronRight } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import NumberFormat from "react-number-format";

export default function CourierList({
  name,
  code,
  selected,
  costs,
  value,
  onChangeCourier = () => {},
  onChangeService = () => {},
}) {
  return (
    <div className="bg-white-100 mb-4">
      <button
        onClick={() => onChangeCourier(code)}
        type="button"
        className="p-5 flex justify-between items-center w-full text-left"
      >
        <div className="flex-1 mr-6">{name || <Skeleton />}</div>
        <div className={"transform " + (selected ? "rotate-90" : "")}>
          <FaChevronRight />
        </div>
      </button>
      <div
        className={
          "border-t border-white-300 overflow-hidden flex flex-col pb-5" +
          (selected ? " block" : " hidden")
        }
      >
        {costs?.map((item, index) => (
          <button
            key={`${index}`}
            type="button"
            onClick={() => {
              onChangeService(item.service, item.cost[0].value);
            }}
            className={
              "mx-5 mt-5 p-3 text-left border flex " +
              (value === `${code} - ${item.service}`
                ? "border-secondary-100"
                : "border-white-200")
            }
          >
            <div className="flex-1 mr-6">
              <div className="font-bold text-lg">{item.service}</div>
              <div className="text-black-300">
                {item.cost[0].etd
                  ? item.cost[0].etd + " Hari"
                  : "Estimasi tidak tersedia"}
              </div>
            </div>
            <div>
              <NumberFormat
                value={item.cost[0].value}
                prefix="Rp "
                displayType="text"
                thousandSeparator={true}
              />
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}
