import Skeleton from "react-loading-skeleton";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
export default function CourseList({
  cover,
  title,
  price,
  id,
  mentor,
  showPrice = true,
}) {
  return (
    <Link
      to={`/course/${id}`}
      onClick={(e) => {
        if (!id) {
          e.preventDefault();
        }
      }}
      className="relative bg-white-100 dark:bg-dark-100 p-3 hover:shadow-md"
    >
      <div className="w-1-1 p-3">
        {cover ? (
          <img
            src={process.env.REACT_APP_BASE_URL + cover}
            className="object-cover"
            alt={title}
          />
        ) : (
          <div />
        )}
      </div>
      <div className="overflow-hidden text-container mt-3">
        {title ? <h3 className="text font-bold">{title}</h3> : <Skeleton />}
      </div>
      {mentor ? (
        <div className="flex items-center text-sm">{mentor}</div>
      ) : (
        <div className="w-1/2 text-sm">
          <Skeleton />
        </div>
      )}
      {typeof price !== undefined ? (
        showPrice ? (
          <div className="flex items-center text-sm">
            {price ? (
              <NumberFormat
                value={Number(price)}
                prefix="Rp "
                displayType="text"
                thousandSeparator={true}
              />
            ) : (
              "FREE"
            )}
          </div>
        ) : null
      ) : (
        <div className="w-1/2 text-sm">
          <Skeleton />
        </div>
      )}
    </Link>
  );
}
