import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

export default function CheckoutItem({ name, price, id, qty, image, type }) {
  return (
    <div className="p-5 rounded-sm text-left hover:shadow-sm mb-3 bg-white-100 dark:bg-dark-100 w-full block">
      <Link
        to={type === "product" ? `/ecommerce/product/${id}` : `/course/${id}`}
        className="flex"
      >
        <div className="w-16">
          <div className="w-1-1">
            <img
              src={process.env.REACT_APP_BASE_URL + image}
              className="object-cover"
              alt={name}
            />
          </div>
        </div>
        <div className="flex-1 ml-4">
          <h2 className="poppins font-bold nl-1">{name}</h2>
          <div className="mt-1">x{qty}</div>
        </div>
        <div className="ml-6 hidden lg:block">
          <NumberFormat
            value={price * qty}
            prefix="Rp "
            displayType="text"
            thousandSeparator={true}
          />
        </div>
      </Link>
    </div>
  );
}
