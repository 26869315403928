import {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet-async";
import { FaChevronLeft, FaFileDownload, FaTimes } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import Boost from "../../components/Boost";
import Button from "../../components/Button";
import CommentList from "../../components/CommentList";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import MainContainer, {
  ColumnOne,
  ColumnTwo,
} from "../../components/MainContainer";
import service from "../../service";

const WithoutChild = forwardRef(
  ({ data = {}, increaseComment = () => {}, boosts }, ref) => {
    const [sending, setSending] = useState(false);
    const _loadRef = useRef();
    const _spanRef = useRef();
    const [comments, setComments] = useState([]);
    const [commentReplies, setCommentReplies] = useState([]);
    const [reply, setReply] = useState(0);

    const send = ({ body }) => {
      setSending(true);
      service
        .post(!reply ? "/comment/" + data.id : "/comment/reply/" + reply, {
          body,
        })
        .then((response) => {
          setSending(false);
          _spanRef.current.innerHTML = "";
          if (reply) {
            increaseComment(true);
            setCommentReplies((value) => value.concat([response.data]));
          } else {
            increaseComment(false);
            setComments((value) => value.concat([response.data]));
          }
        })
        .catch((e) => {
          setSending(false);
        });
    };

    const _getData = (id) => {
      service
        .get("/comment/" + id)
        .then((response) => {
          setComments(response.data);
        })
        .catch((e) => {});
    };
    const _getDataReplies = (id) => {
      service
        .get("/comment/reply/" + id)
        .then((response) => {
          setCommentReplies(response.data);
        })
        .catch((e) => {});
    };

    useEffect(() => {
      if (_loadRef.current) {
        if (sending) {
          _loadRef.current.show();
        } else {
          _loadRef.current.hide();
        }
      }
    }, [sending]);

    useImperativeHandle(
      ref,
      () => ({
        refresh: _getData,
      }),
      []
    );

    useEffect(() => {
      setCommentReplies([]);
      if (reply) {
        _getDataReplies(reply);
      }
    }, [reply]);
    return (
      <MainContainer>
        <Helmet>{data.title && <title>{data.title}</title>}</Helmet>
        <ColumnOne>
          <ul className="breadcrumb">
            <li>
              {data.title ? (
                <Link to="/home">Home</Link>
              ) : (
                <div className="w-20">
                  <Skeleton />
                </div>
              )}
            </li>
            <li>
              {data.title ? (
                <Link to="/course">Online Course</Link>
              ) : (
                <div className="w-20">
                  <Skeleton />
                </div>
              )}
            </li>
            {data?.course ? (
              <li>
                <Link to={`/course/${data.course?.id}`}>
                  {data.course?.title}
                </Link>
              </li>
            ) : null}
          </ul>
          <Header>
            {data.title ? (
              <Fragment>{data.title}</Fragment>
            ) : (
              <div className="w-2/3">
                <Skeleton />
              </div>
            )}
          </Header>
          <div className="mt-10 pb-8">
            {data.body ? (
              <Fragment>
                <div className="mb-3 pb-3">
                  {data.video ? (
                    <div className="mb-4">
                      <ReactPlayer
                        url={data.video}
                        controls
                        width="100%"
                        height="auto"
                        config={{
                          file: {
                            attributes: {
                              controlsList: "nodownload",
                            },
                          },
                        }}
                      />
                    </div>
                  ) : null}
                  {data.audio || data.pdf ? (
                    <div className="flex mb-4 items-center">
                      {data.audio ? (
                        <div className="mr-2 flex-1">
                          <ReactPlayer
                            url={process.env.REACT_APP_BASE_URL + data.audio}
                            controls
                            width="100%"
                            height={50}
                            config={{
                              file: {
                                attributes: {
                                  controlsList: "nodownload",
                                },
                              },
                            }}
                          />
                        </div>
                      ) : null}
                      {data.pdf ? (
                        <Button
                          rawLink="true"
                          link={true}
                          href={process.env.REACT_APP_BASE_URL + data.pdf}
                        >
                          <FaFileDownload /> PDF
                        </Button>
                      ) : null}
                    </div>
                  ) : null}
                  <div className={"transition-all duration-200 gotham-book"}>
                    <div
                      className="text-black-200 dark:text-white-300 text-justify noreset"
                      dangerouslySetInnerHTML={{ __html: data.body }}
                    ></div>
                  </div>
                </div>
                <Boost boosted={data.is_boosted} boosts={boosts} id={data.id} />
              </Fragment>
            ) : (
              <Skeleton count={8} />
            )}
          </div>
        </ColumnOne>
        <ColumnTwo
          after={
            data.title ? (
              <form
                className="px-5 lg:px-8 py-5 border-t border-white-400"
                onSubmit={(e) => {
                  e.preventDefault();
                  const body = _spanRef.current.innerHTML;
                  if (!body) {
                    _spanRef.current.focus();
                  } else {
                    send({
                      body,
                    });
                  }
                }}
              >
                {reply ? (
                  <div className="font-bold text-sm mb-2 flex">
                    Membalas{" "}
                    {comments.find((el) => el.id === reply).user?.fullname}
                    <button
                      type="button"
                      className="ml-2 font-normal text-red-100"
                      onClick={() => {
                        setReply(0);
                      }}
                    >
                      <FaTimes />
                    </button>
                  </div>
                ) : null}
                <span
                  ref={_spanRef}
                  contentEditable={!sending}
                  role="textbox"
                  className="border border-white-300 rounded-none w-full p-3 block bg-white-100 mb-3"
                  style={{ minHeight: 80 }}
                ></span>
                <Button
                  type="submit"
                  className="text-sm p-1"
                  disabled={sending}
                >
                  <Loader
                    className="bg-black-100"
                    placeholder="Kirim"
                    ref={_loadRef}
                  />
                </Button>
              </form>
            ) : null
          }
        >
          {reply ? (
            <Fragment>
              <h2 className="poppins font-bold mb-5 flex items-center -mt-2">
                <button
                  className="text-black-300 p-2 flex items-center justify-center hover:bg-white-300 mr-2 rounded-full -ml-2"
                  type="button"
                  onClick={() => {
                    setReply(0);
                  }}
                >
                  <FaChevronLeft />
                </button>
                Balasan
              </h2>
              <CommentList
                mentor={comments.find((el) => el.id === reply).is_mentor}
                inReplyPage={true}
                name={comments.find((el) => el.id === reply).user?.fullname}
                replies={comments.find((el) => el.id === reply).replies_count}
                body={comments.find((el) => el.id === reply).body}
                onReply={() =>
                  setReply(comments.find((el) => el.id === reply).id)
                }
              />
              <div className="border-l-2 border-white-500 pl-3">
                {commentReplies.length < 1 ? (
                  <div className="bg-white-100 p-5 text-center">
                    Belum ada balasan
                  </div>
                ) : null}
                {commentReplies.map((item, index) => (
                  <CommentList
                    mentor={item.is_mentor}
                    key={`${index}`}
                    name={item.user?.fullname}
                    replies={item.replies_count}
                    body={item.body}
                    inReplyPage={true}
                  />
                ))}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <h2 className="poppins font-bold mb-5">
                {data.comments_count} Diskusi
              </h2>
              {comments.length < 1 ? (
                <div className="bg-white-100 p-5 text-center">
                  Belum ada diskusi
                </div>
              ) : null}
              {comments.map((item, index) => (
                <CommentList
                  key={`${index}`}
                  name={item.user?.fullname}
                  replies={item.replies_count}
                  body={item.body}
                  onReply={() => setReply(item.id)}
                  mentor={item.is_mentor}
                />
              ))}
            </Fragment>
          )}
        </ColumnTwo>
      </MainContainer>
    );
  }
);

export default WithoutChild;
