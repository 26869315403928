import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { setPath } from "../../redux/reducers/interface";
import service from "../../service";
import WithChild from "./WithChild";
import WithoutChild from "./WithoutChild";

export default function SubjectDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [data, setData] = useState({});
  const history = useHistory();
  const _withoutChildRef = useRef();
  const [firstLoad, setFirstLoad] = useState(true);
  const [boosts, setBoosts] = useState([]);

  const _getDataBoost = () => {
    service
      .get("/boost/" + id)
      .then((response) => {
        setBoosts(response.data);
      })
      .catch((e) => {});
  };

  const _getData = () => {
    service
      .get(`/subject/${id}`)
      .then((response) => {
        setFirstLoad(true);
        setData(response.data);
      })
      .catch((e) => {
        setFirstLoad(true);
        if (e.response?.status === 404) {
          history.replace("/");
        }
      });
  };

  useEffect(() => {
    dispatch(setPath("/course"));
  }, [dispatch]);

  useEffect(() => {
    setFirstLoad(false);
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (!firstLoad) {
      _getData();
    }
  }, [firstLoad]);

  useEffect(() => {
    if (data.id) {
      if (data.childs?.length < 1) {
        _withoutChildRef.current.refresh(id);
      }
      _getDataBoost();
    }
  }, [data.id]);
  return (
    <Fragment>
      {data.childs?.length > 0 ? (
        <WithChild data={data} boosts={boosts} />
      ) : (
        <WithoutChild
          ref={_withoutChildRef}
          data={data}
          boosts={boosts}
          increaseComment={(refresh) => {
            setData((value) => {
              const temp = value;
              temp.comments_count = Number(temp.comments_count || "0") + 1;
              return { ...temp };
            });
            if (refresh) {
              _withoutChildRef.current.refresh(id);
            }
          }}
        />
      )}
    </Fragment>
  );
}
