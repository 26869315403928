import { Fragment, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Skeleton from "react-loading-skeleton";
import ReactPlayer from "react-player";
import { Link, useHistory, useParams } from "react-router-dom";
import BabList from "../components/BabList";
import Header from "../components/Header";
import MainContainer, {
  ColumnOne,
  ColumnTwo,
} from "../components/MainContainer";
import service from "../service";

export default function Detail() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [detail, setDetail] = useState(false);
  const history = useHistory();

  const _getData = useCallback(() => {
    service
      .get(`/content/${id}/detail`)
      .then((response) => {
        setData(response.data);
      })
      .catch((e) => {
        if (e.response?.status === 404) {
          history.replace("/");
        }
      });
  }, [id, history]);

  useEffect(() => {
    _getData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <Helmet>{data.title && <title>{data.title}</title>}</Helmet>
      <ColumnOne>
        <ul className="breadcrumb">
          <li>
            {data.title ? (
              <Link to="/home">Home</Link>
            ) : (
              <div className="w-20">
                <Skeleton />
              </div>
            )}
          </li>
        </ul>
        <Header>
          {data.title || (
            <div className="w-2/3">
              <Skeleton />
            </div>
          )}
        </Header>
        <div className="flex flex-col md:flex-row mt-10 pb-8">
          <div className="w-1/2 md:w-1/3 mx-auto md:mx-0">
            <div className="bg-white-100 dark:bg-dark-100 p-2 box-border">
              <div className="w-1-1 box-border">
                {data.cover ? (
                  <img
                    alt={data.title}
                    src={process.env.REACT_APP_BASE_URL + data.cover}
                    className="object-cover"
                  />
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
          <div className="ml-0 mt-8 md:ml-5 md:mt-0 flex-1">
            {data.synopsis ? (
              <Fragment>
                <div className="mb-3 flex items-start border-b border-white-400">
                  <div className="font-bold w-20">Author</div>
                  <div className="flex-1 flex flex-wrap">
                    {data.authors?.map((item, index) => (
                      <Fragment key={`${index}`}>
                        {index > 0 && "·"}
                        <span className="mb-3 mx-3">{item.name}</span>
                      </Fragment>
                    ))}
                  </div>
                </div>
                <div className="mb-3 flex items-start border-b border-white-400">
                  <div className="font-bold w-20">Kategori</div>
                  <div className="flex-1 flex flex-wrap">
                    {data.categories?.map((item, index) => (
                      <Fragment key={`${index}`}>
                        {index > 0 && "·"}
                        <span className="mb-3 mx-3">{item.name}</span>
                      </Fragment>
                    ))}
                  </div>
                </div>
                <div className="mb-3 pb-3">
                  {data.audio && (
                    <div>
                      <ReactPlayer
                        url={process.env.REACT_APP_BASE_URL + data.audio}
                        controls
                        height={50}
                        width="100%"
                        config={{
                          file: {
                            attributes: {
                              controlsList: "nodownload",
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                  <div
                    className={
                      "transition-all duration-200 gotham-book " +
                      (detail ? " max-h-full" : "sinopsis")
                    }
                  >
                    <div
                      className="text-black-200 dark:text-white-300 text-justify noreset"
                      dangerouslySetInnerHTML={{ __html: data.synopsis }}
                    ></div>
                  </div>
                  <button
                    type="button"
                    className="mt-5 font-bold text-primary-200 dark:text-secondary-100"
                    onClick={() => {
                      setDetail((value) => !value);
                    }}
                  >
                    {detail ? "TUTUP" : "BACA SELENGKAPNYA"}
                  </button>
                </div>
              </Fragment>
            ) : (
              <Skeleton count={8} />
            )}
          </div>
        </div>
      </ColumnOne>
      <ColumnTwo>
        <h2 className="poppins font-bold mb-5">Daftar Ring</h2>
        {(data.babs ? data.babs : [{}, {}, {}, {}, {}]).map((item, index) => (
          <BabList
            id={item.id}
            key={`bab-${index}`}
            ring={data.babs ? `${index + 1}/${data.babs?.length}` : null}
            title={item.title}
          />
        ))}
      </ColumnTwo>
    </MainContainer>
  );
}
