import { createSlice } from "@reduxjs/toolkit";

const interfaceSlice = createSlice({
  name: "interface",
  initialState: {
    activePath: "/home",
    logo: "",
  },
  reducers: {
    setPath: (state, { payload: path }) => {
      return {
        ...state,
        activePath: path,
      };
    },
  },
});

export const { setPath } = interfaceSlice.actions;

export default interfaceSlice.reducer;
