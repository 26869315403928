import { Fragment } from "react";

export function Heading({ title, subtitle, scheme = "white" }) {
  let textColorPrimary = "text-white-100 ";
  let textColorSecondary = "text-white-300 ";
  if (scheme === "black") {
    textColorPrimary = "text-black-100 ";
    textColorSecondary = "text-black-200 ";
  }
  return (
    <Fragment>
      <div
        className={
          textColorPrimary +
          "text-xl lg:text-2xl poppins font-bold dark:text-white-400"
        }
      >
        {title}
      </div>
      <div className={textColorSecondary + "roboto dark:text-white-500"}>
        {subtitle}
      </div>
    </Fragment>
  );
}

export default function Header({ children }) {
  return (
    <Fragment>
      <div className="-m-5 lg:-m-8 mb-0 lg:mb-0 p-5 py-8 lg:p-8 bg-primary-100">
        {children}
      </div>
      <div className="h-8 border-t-4 border-secondary-100 -mt-1 mb-1" />
    </Fragment>
  );
}
