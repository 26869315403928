import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaCartPlus, FaTrashAlt } from "react-icons/fa";
import { GoStar } from "react-icons/go";
import Skeleton from "react-loading-skeleton";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Button from "../components/Button";
import Header from "../components/Header";
import Loader from "../components/Loader";
import MainContainer, {
  ColumnOne,
  ColumnTwo,
} from "../components/MainContainer";
import ReviewList from "../components/ReviewList";
import SubjectList from "../components/SubjectList";
import { addItem, resetQty } from "../redux/reducers/cart";
import { setPath } from "../redux/reducers/interface";
import service from "../service";

export default function CourseDetail() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [detail, setDetail] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isMember, setIsMember] = useState(false);
  const [isMentor, setIsMentor] = useState(false);
  const [star, setStar] = useState(0);
  const _spanRef = useRef();
  const _reviewLoadRef = useRef();
  const [sending, setSending] = useState(false);
  const [isReviewed, setIsReviewed] = useState(false);
  const [reviews, setReviews] = useState([{}, {}, {}]);
  const [joining, setJoining] = useState(false);
  const cart = useSelector((state) => state.cart.items);

  const sendReview = () => {
    const body = _spanRef.current.innerHTML;
    if (star && body) {
      setSending(true);
      _reviewLoadRef.current.show();
      service
        .post("/review/" + id, {
          star,
          body,
        })
        .then((response) => {
          _reviewLoadRef.current.hide();
          setSending(false);
          setIsReviewed(true);
          setReviews((value) => {
            return [response.data, ...value];
          });
        })
        .catch((e) => {
          _reviewLoadRef.current.hide();
          setSending(false);
        });
    }
  };

  const _joining = () => {
    setJoining(true);
    service
      .post("/course/join/" + id)
      .then((response) => {
        setJoining(false);
        setIsMember(true);
      })
      .catch((e) => {
        setJoining(false);
      });
  };

  const _getData = useCallback(() => {
    service
      .get("/review/" + id)
      .then((response) => {
        setReviews(response.data);
      })
      .catch((e) => {
        setReviews([]);
      });
    service
      .get(`/course/${id}`)
      .then((response) => {
        setData(response.data);
        setIsMember(response.data.is_member);
        setIsMentor(response.data.is_mentor);
        setIsReviewed(response.data.is_reviewed);
      })
      .catch((e) => {
        if (e.response?.status === 404) {
          history.replace("/");
        }
      });
  }, [id, history]);

  useEffect(() => {
    _getData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(setPath("/course"));
  }, [dispatch]);

  return (
    <MainContainer>
      <Helmet>{data.title && <title>{data.title}</title>}</Helmet>
      <ColumnOne>
        <ul className="breadcrumb">
          <li>
            {data.title ? (
              <Link to="/home">Home</Link>
            ) : (
              <div className="w-20">
                <Skeleton />
              </div>
            )}
          </li>
          <li>
            {data.title ? (
              <Link to="/course">Online Course</Link>
            ) : (
              <div className="w-20">
                <Skeleton />
              </div>
            )}
          </li>
        </ul>
        <Header>
          {data.title ? (
            <Fragment>{data.title}</Fragment>
          ) : (
            <div className="w-2/3">
              <Skeleton />
            </div>
          )}
        </Header>
        <div className="flex flex-col md:flex-row mt-10 pb-8">
          <div className="w-1/2 md:w-1/3 mx-auto md:mx-0">
            <div className="bg-white-100 dark:bg-dark-100 p-2 box-border">
              <div className="w-1-1 box-border">
                {data.cover ? (
                  <img
                    alt={data.title}
                    src={process.env.REACT_APP_BASE_URL + data.cover}
                    className="object-cover"
                  />
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
          <div className="ml-0 mt-8 md:ml-5 md:mt-0 flex-1">
            {data.description ? (
              <Fragment>
                <div className="mb-3 flex items-start border-b border-white-400">
                  <div className="font-bold w-20">Harga</div>
                  <div className="flex-1 flex flex-wrap">
                    <span className="mb-3 mx-3">
                      <NumberFormat
                        value={Number(data.price)}
                        prefix="Rp "
                        displayType="text"
                        thousandSeparator={true}
                      />
                    </span>
                  </div>
                </div>
                <div className="mb-3 flex items-start border-b border-white-400">
                  <div className="font-bold w-20">Mentor</div>
                  <div className="flex-1 flex flex-wrap">
                    {data.users?.map((item, index) => (
                      <Fragment key={`${index}`}>
                        {index > 0 && "·"}
                        <span className="mb-3 mx-3">{item.fullname}</span>
                      </Fragment>
                    ))}
                  </div>
                </div>
                <div className="mb-3 pb-3">
                  <div
                    className={
                      "transition-all duration-200 gotham-book " +
                      (detail ? " max-h-full" : "sinopsis")
                    }
                  >
                    <div
                      className="text-black-200 dark:text-white-300 text-justify noreset"
                      dangerouslySetInnerHTML={{ __html: data.description }}
                    ></div>
                  </div>
                  <button
                    type="button"
                    className="mt-5 font-bold text-primary-200 dark:text-secondary-100"
                    onClick={() => {
                      setDetail((value) => !value);
                    }}
                  >
                    {detail ? "TUTUP" : "BACA SELENGKAPNYA"}
                  </button>
                  <div className="mt-8 flex items-center">
                    {isMember ? null : data.price ? (
                      <Button
                        type="button"
                        color={
                          !(cart.findIndex((el) => el.id === data.id) >= 0)
                            ? "yellow"
                            : "red"
                        }
                        onClick={() => {
                          const index = cart.findIndex(
                            (el) => el.id === data.id
                          );
                          if (!(index >= 0)) {
                            dispatch(
                              addItem({
                                id: data.id,
                                name: data.title,
                                price: data.price,
                                qty: 1,
                                image: data.cover,
                                type: "course",
                              })
                            );
                          } else {
                            dispatch(resetQty({ index, qty: 0 }));
                          }
                        }}
                      >
                        {!(cart.findIndex((el) => el.id === data.id) >= 0) ? (
                          <Fragment>
                            <FaCartPlus className="mr-3" /> Keranjang
                          </Fragment>
                        ) : (
                          <Fragment>
                            <FaTrashAlt className="mr-3" /> Keranjang
                          </Fragment>
                        )}
                      </Button>
                    ) : (
                      <Button
                        disabled={joining}
                        type="button"
                        color={"yellow"}
                        onClick={() => {
                          _joining();
                        }}
                      >
                        Join
                      </Button>
                    )}
                    {cart.findIndex((el) => el.id === data.id) >= 0 &&
                    !isMember ? (
                      <Button
                        color="white"
                        className="ml-3"
                        link={true}
                        to="/ecommerce"
                      >
                        Lihat Keranjang
                      </Button>
                    ) : null}
                  </div>
                </div>
              </Fragment>
            ) : (
              <Skeleton count={8} />
            )}
          </div>
        </div>
        <Header>Review</Header>
        {isReviewed || (data.title && isMentor) || !isMember ? null : (
          <div className="bg-white-100 p-5 my-3">
            <div>
              {[1, 2, 3, 4, 5].map((item, index) => (
                <button
                  type="button"
                  className={`text-3xl mr-3 ${
                    star >= item ? "text-secondary-100" : "text-black-300"
                  }`}
                  key={`${index}`}
                  onClick={() => {
                    if (!sending) {
                      setStar(item);
                    }
                  }}
                >
                  <GoStar />
                </button>
              ))}
            </div>
            <span
              ref={_spanRef}
              contentEditable={!sending}
              role="textbox"
              className="border border-white-300 rounded-none w-full p-3 block bg-white-100 mb-3 mt-3"
              style={{ minHeight: 80 }}
            ></span>
            <Button type="button" onClick={() => sendReview()}>
              <Loader
                className="bg-black-100"
                placeholder="Kirim"
                ref={_reviewLoadRef}
              />
            </Button>
          </div>
        )}
        {reviews.length ? (
          reviews.map((item, index) => (
            <ReviewList
              key={`${index}`}
              fullname={item.user?.fullname}
              date={item.created_at}
              star={item.star}
            >
              {item.body}
            </ReviewList>
          ))
        ) : (
          <div className="bg-white-100 p-5 text-center mt-5">
            Belum Ada Review
          </div>
        )}
      </ColumnOne>
      <ColumnTwo>
        <h2 className="poppins font-bold mb-5">Daftar Materi</h2>
        {data && data.subjects?.length < 1 ? (
          <div className="bg-white-100 p-3 text-center">Belum ada materi</div>
        ) : null}
        {(!data.subjects ? [{}, {}, {}] : data?.subjects).map((item, index) => (
          <SubjectList
            member={isMember}
            key={`${index}`}
            childs={item.childs}
            id={item.id}
          >
            {item.title}
          </SubjectList>
        ))}
      </ColumnTwo>
    </MainContainer>
  );
}
