import moment from "moment";
import { Fragment, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Container from "../components/AuthPages/Container";
import Header, { Heading } from "../components/AuthPages/Header";
import InnerContainer from "../components/AuthPages/InnerContainer";
import Button from "../components/Button";
import Loader from "../components/Loader";
import TextInput from "../components/TextInput";
import errorMessage from "../error-message";
import service from "../service";

const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export default function Register() {
  const _loadRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const setCookies = useCookies()[1];

  const _proceedRegister = ({ username, fullname, email, password }) => {
    _loadRef.current.show();
    service
      .post("/auth/user/register", {
        username,
        fullname,
        password,
        email,
      })
      .then((response) => {
        _loadRef.current.hide();
        const { id, token } = response.data;
        setCookies("id", id, {
          path: "/",
          expires: moment().add(10, "years").toDate(),
        });
        setCookies("token", token, {
          path: "/",
          expires: moment().add(10, "years").toDate(),
        });
        setCookies("logo", `${process.env.REACT_APP_BASE_URL}/base-logo.png`, {
          path: "/",
          expires: moment().add(10, "years").toDate(),
        });
      })
      .catch((e) => {
        _loadRef.current.hide();
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Buat Akun</title>
      </Helmet>
      <Container>
        <InnerContainer>
          <Header>
            <Heading
              title="Buat Akun"
              subtitle="Buat akun baring anda untuk memulai"
            />
          </Header>
          <form onSubmit={handleSubmit(_proceedRegister)}>
            <TextInput
              type="text"
              label="Nama Lengkap"
              containerClassName="mb-5"
              {...register("fullname", { required: errorMessage.required })}
              message={errors.fullname?.message}
            />
            <TextInput
              type="text"
              label="Username"
              containerClassName="mb-5"
              {...register("username", { required: errorMessage.required })}
              message={errors.username?.message}
            />
            <TextInput
              type="text"
              label="Email"
              containerClassName="mb-5"
              {...register("email", {
                required: errorMessage.required,
                pattern: {
                  value: emailRegex,
                  message: errorMessage.emailInvalid,
                },
              })}
              message={errors.email?.message}
            />
            <TextInput
              type="password"
              label="Password"
              containerClassName="mb-5"
              {...register("password", { required: errorMessage.required })}
              message={errors.password?.message}
            />
            <Button
              className="flex w-full"
              type="submit"
              disabled={_loadRef.current?.status() || false}
            >
              <Loader
                ref={_loadRef}
                placeholder="Mendaftar"
                className="bg-black-100"
              />
            </Button>
            <div className="h-5 border-t border-white-200 dark:border-dark-200 mt-5" />
            <div className="text-center roboto text-black-200 dark:text-white-500">
              Sudah punya akun? <br />
              <Link
                to="/auth/login"
                className="font-bold text-black-100 dark:text-white-400"
              >
                Login
              </Link>
            </div>
          </form>
        </InnerContainer>
      </Container>
    </Fragment>
  );
}
