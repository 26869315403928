import moment from "moment";
import "moment/locale/id";
import Skeleton from "react-loading-skeleton";
import NumberFormat from "react-number-format";
import Button from "./Button";

const Item = ({ name, price, qty, image }) => (
  <div className="p-5 rounded-sm text-left mb-3 bg-white-100 dark:bg-dark-100 w-full block border border-white-200">
    <div className="flex">
      <div className="w-16 flex-shrink-0">
        <div className="w-1-1">
          {image ? (
            <img
              src={process.env.REACT_APP_BASE_URL + image}
              className="object-cover"
              alt={name}
            />
          ) : (
            <div className="w-full h-full bg-white-200" />
          )}
        </div>
      </div>
      <div className="flex-1 ml-4">
        <h2 className="poppins font-bold nl-1">{name}</h2>
        <div className="mt-1">{Number(qty) > 0 ? `x${qty}` : qty}</div>
      </div>
      <div className="ml-6 hidden lg:block">
        <NumberFormat
          value={price * qty}
          prefix="Rp "
          displayType="text"
          thousandSeparator={true}
        />
      </div>
    </div>
  </div>
);

export default function CheckoutList({ date, id, items = [] }) {
  return (
    <div className="bg-white-100 mb-4">
      <div className="mb-4 p-4 border-b border-white-300 text-black-300 text-sm">
        {date ? (
          moment(date).locale("id").format("DD MMM YYYY, HH:mm")
        ) : (
          <div className="w-1/2 lg:w-1/4">
            <Skeleton />
          </div>
        )}
      </div>
      <div className="px-4">
        {items.length > 0 ? (
          items.map((item, index) => (
            <Item
              key={`${index}`}
              price={item.price}
              name={item.title}
              qty={item.qty}
              image={(item.product || item.course)?.cover}
            />
          ))
        ) : (
          <Item
            name={
              <div className="w-full lg:w-1/2">
                <Skeleton />
              </div>
            }
            qty={
              <div className="w-1/2 lg:w-1/5">
                <Skeleton />
              </div>
            }
          />
        )}
      </div>
      <div className="px-4 pb-4">
        {moment().diff(moment(date), "days") <= 2 ? (
          <Button link={true} to={`/payment/${id}`}>
            Bayar
          </Button>
        ) : null}
      </div>
    </div>
  );
}
