import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartItem from "../../components/CartItem";
import MainContainer, { ColumnTwo } from "../../components/MainContainer";
import { setPath } from "../../redux/reducers/interface";
import Button from "../../components/Button";
import { FaArrowRight } from "react-icons/fa";

export default function Template({ children }) {
  const cart = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPath("/ecommerce"));
  }, [dispatch]);

  return (
    <MainContainer>
      {children}
      <ColumnTwo>
        <h2 className="poppins font-bold mb-5">Keranjang Belanja</h2>
        {cart?.map((item, index) => (
          <CartItem
            key={`${index}`}
            price={item.price}
            name={item.name}
            id={item.id}
            qty={item.qty}
            image={item.image}
            index={index}
            type={item.type}
          />
        ))}
        {cart.length < 1 ? (
          <div className="bg-white-100 dark:bg-dark-100 dark:text-white-100 p-5 text-center rounded-sm">
            Belum ada item
          </div>
        ) : (
          <Button
            link={true}
            to="/checkout"
            className="w-full flex justify-between"
          >
            Checkout
            <FaArrowRight />
          </Button>
        )}
      </ColumnTwo>
    </MainContainer>
  );
}
