import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { GoPencil } from "react-icons/go";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Button from "../components/Button";
import CheckoutList from "../components/CheckoutList";
import CourseList from "../components/CourseList";
import Loader from "../components/Loader";
import NotFound from "../components/NotFound";
import TextInput from "../components/TextInput";
import { setPath } from "../redux/reducers/interface";
import service from "../service";

const isFile = (input) => "File" in window && input instanceof File;

const Information = () => {
  const [data, setData] = useState({});
  const [cookies] = useCookies();
  const [editMode, setEditMode] = useState(false);
  const _inputPhoto = useRef();
  const _loadRef = useRef();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullname: "",
      avatar: "",
    },
  });

  const proceedUpdate = ({ avatar, fullname }) => {
    _loadRef.current.show();
    const formData = new FormData();
    formData.append("fullname", fullname);
    if (isFile(avatar)) {
      formData.append("avatar", avatar);
    }

    service
      .put("/user/change-profile", formData)
      .then((response) => {
        _loadRef.current.hide();
        setData(response.data);
        setEditMode(false);
      })
      .catch(() => {
        _loadRef.current.hide();
        setEditMode(false);
      });
  };

  useEffect(() => {
    service
      .get("/user/" + cookies.id)
      .then((response) => {
        setData(response.data);
      })
      .catch((e) => {});
  }, [cookies.id]);

  useEffect(() => {
    if (!editMode) {
      reset({
        fullname: data.fullname,
        avatar: data.avatar ? process.env.REACT_APP_BASE_URL + data.avatar : "",
      });
    }
  }, [editMode, data]);

  return (
    <div className="p-5 lg:p-8 flex-1">
      <form
        className="flex flex-col lg:flex-row"
        onSubmit={handleSubmit(proceedUpdate)}
      >
        <div className="flex justify-center items-start">
          <div className="relative mx-auto group">
            {watch("avatar") ? (
              <img
                src={
                  isFile(watch("avatar"))
                    ? URL.createObjectURL(watch("avatar"))
                    : watch("avatar")
                }
                className="w-32 h-32 object-contain bg-black-100"
              />
            ) : (
              <div className="w-32 h-32 bg-white-100" />
            )}
            {editMode ? (
              <button
                type="button"
                onClick={() => {
                  _inputPhoto.current.click();
                }}
                className="w-8 h-8 absolute top-0 right-0 bg-dark-100 m-1 rounded bg-opacity-50 group-hover:bg-opacity-80 flex justify-center items-center text-white-100"
              >
                <GoPencil />
              </button>
            ) : null}
          </div>
        </div>
        <div className="flex-1 ml-0 lg:ml-6 mt-6 lg:mt-0">
          {editMode ? (
            <Fragment>
              <input
                type="file"
                className="hidden"
                ref={_inputPhoto}
                multiple={false}
                accept=".jpg,.jpeg,.png"
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    setValue("avatar", e.target.files[0]);
                  }
                }}
              />
              <TextInput
                label="Nama"
                type="text"
                containerClassName="mb-4"
                {...register("fullname", { required: "Harap diisi" })}
                message={errors.fullname?.message}
              />
              <TextInput
                label="Username"
                value={data.username}
                type="text"
                readOnly={true}
                containerClassName="mb-4"
              />
              <TextInput
                label="Email"
                value={data.email}
                type="text"
                readOnly={true}
                containerClassName="mb-4"
              />
            </Fragment>
          ) : (
            <Fragment>
              <div className="border-b border-white-300 mb-3 pb-3">
                <div className="text-sm">Nama</div>
                <div className="font-bold">{data.fullname || <Skeleton />}</div>
              </div>
              <div className="border-b border-white-300 mb-3 pb-3">
                <div className="text-sm">Username</div>
                <div className="font-bold">{data.username || <Skeleton />}</div>
              </div>
              <div className="border-b border-white-300 mb-3 pb-3">
                <div className="text-sm">Email</div>
                <div className="font-bold">{data.email || <Skeleton />}</div>
              </div>
              <div className="border-b border-white-300 mb-3 pb-3">
                <div className="text-sm">Expired</div>
                <div className="font-bold">
                  {data.subscription_end ? (
                    moment(data.subscription_end).format("YYYY-MM-DD") ===
                    "2037-12-31" ? (
                      "Lifetime"
                    ) : (
                      moment(data.subscription_end).format("DD MMMM YYYY")
                    )
                  ) : (
                    <Skeleton />
                  )}
                </div>
              </div>
            </Fragment>
          )}
          <div>
            {editMode ? (
              <Button
                type="submit"
                className="mr-6 w-32"
                disabled={_loadRef.current?.status() || false}
              >
                <Loader
                  className="bg-black-100"
                  placeholder="Simpan"
                  ref={_loadRef}
                />
              </Button>
            ) : null}
            <Button
              color="black"
              type="button"
              onClick={() => setEditMode((value) => !value)}
            >
              {editMode ? "Batal" : "Edit Profile"}
            </Button>
          </div>
          <Button
            link={true}
            to="/logout"
            className="block w-full lg:hidden mt-12 text-red-100 h-12"
          >
            Keluar
          </Button>
        </div>
      </form>
    </div>
  );
};

const Transaction = () => {
  const [data, setData] = useState([{}, {}, {}, {}]);

  const _getData = (page = 1) => {
    service
      .get("/checkout/list", {
        params: {
          page,
        },
      })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((e) => {
        setData([]);
      });
  };

  useEffect(() => {
    _getData(1);
  }, []);
  return (
    <div className="p-5 lg:p-8 flex-1">
      {data.length > 0 ? (
        data.map((item, index) => (
          <CheckoutList
            key={`${index}`}
            date={item.created_at}
            items={item.items}
            id={item.id}
          />
        ))
      ) : (
        <NotFound />
      )}
    </div>
  );
};

const Courses = () => {
  const [data, setData] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]);

  const _getData = (page = 1) => {
    service
      .get("/course/list", {
        params: {
          page,
        },
      })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((e) => {
        setData([]);
      });
  };

  useEffect(() => {
    _getData(1);
  }, []);

  return (
    <div className="p-5 lg:p-8 flex-1">
      {data.length > 0 ? (
        <div className="grid grid-flow-row grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 lg:gap-8 pb-8 box-border">
          {data.map((item, index) => (
            <CourseList
              showPrice={false}
              key={`${index}`}
              id={item.id}
              title={item.title}
              price={item.price}
              cover={item.cover}
              mentor={item.users && item.users[0]?.fullname}
            />
          ))}
        </div>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

const SideButton = ({ active, children, ...props }) => (
  <Link
    className={
      "border-b-2 lg:border-b-0 border-r-0 lg:border-r-2 px-5 py-3 hover:bg-white-200 font-bold w-auto lg:w-full h-full lg:h-auto flex items-center " +
      (active ? "border-secondary-100" : "border-white-100")
    }
    {...props}
  >
    {children}
  </Link>
);

export default function Account() {
  const dispatch = useDispatch();
  const { page } = useParams();

  useEffect(() => {
    dispatch(setPath("/account/information"));
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Fragment>
      <Helmet>
        <title>Akun</title>
      </Helmet>
      <div className="w-full lg:w-1/5 bg-white-100 h-16 lg:h-screen fixed flex flex-row lg:flex-col items-center lg:items-start overflow-auto">
        <SideButton active={page === "information"} to="/account/information">
          Profile
        </SideButton>
        <SideButton active={page === "membership"} to="/account/membership">
          Membership
        </SideButton>
        <SideButton active={page === "like"} to="/account/like">
          Disukai
        </SideButton>
        <SideButton active={page === "courses"} to="/account/courses">
          Course
        </SideButton>
        <SideButton active={page === "transactions"} to="/account/transactions">
          Pembelian
        </SideButton>
        <SideButton active={page === "clipper"} to="/account/clipper">
          Clipper
        </SideButton>
      </div>
      <div className="flex flex-col lg:flex-row ml-0 lg:ml-6">
        <div className="w-auto lg:w-1/5 h-16 lg:h-auto"></div>
        {page === "information" ? <Information /> : null}
        {page === "courses" ? <Courses /> : null}
        {page === "transactions" ? <Transaction /> : null}
      </div>
    </Fragment>
  );
}
