import { Slider } from "@material-ui/core";
import { useRef, useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import { BiSkipPrevious, BiSkipNext } from "react-icons/bi";
import ReactPlayer from "react-player";

export default function Player({
  url,
  handleNext,
  handlePrev,
  autoplay = false,
  handleEnd,
}) {
  const [play, setPlay] = useState(autoplay);
  const [duration, setDuration] = useState(0);
  const [seek, setSeek] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const _player = useRef();
  const [played, setPlayed] = useState(0);

  const leadingZero = (value) => {
    if (value < 10) {
      return `0${value}`;
    }
    return `${value}`;
  };

  const parsingSeconds = (data) => {
    let seconds = Math.floor(data);
    let minutes = Math.floor(data / 60);
    seconds = seconds - minutes * 60;
    let result = `${leadingZero(minutes)}:${leadingZero(seconds)}`;
    if (minutes > 59) {
      let hours = Math.floor(minutes / 60);
      minutes = minutes - hours * 60;
      result = `${leadingZero(hours)}:${leadingZero(minutes)}:${leadingZero(
        seconds
      )}`;
    }

    return result;
  };

  return (
    <div className="flex h-full items-center">
      <ReactPlayer
        url={url}
        width={0}
        height={0}
        playing={play}
        ref={_player}
        onEnded={() => {
          setPlay(false);
          handleEnd();
        }}
        onDuration={(duration) => {
          setDuration(duration);
        }}
        onProgress={({ playedSeconds }) => {
          if (duration && playedSeconds && !seeking) {
            setPlayed(playedSeconds);
            setSeek((playedSeconds / duration) * 100);
          }
        }}
      />

      <div className="px-5 flex items-center ">
        <button
          className="w-8 h-8 flex justify-center items-center"
          type="button"
          onClick={() => handlePrev()}
        >
          <BiSkipPrevious size={24} />
        </button>
        <button
          className="w-8 h-8 flex justify-center items-center"
          type="button"
          onClick={() => {
            setPlay((value) => !value);
          }}
        >
          {!play ? <FaPlay /> : <FaPause />}
        </button>
        <button
          className="w-8 h-8 flex justify-center items-center"
          type="button"
          onClick={() => handleNext()}
        >
          <BiSkipNext size={24} />
        </button>
      </div>
      <span className="px-5 text-sm">{parsingSeconds(played)}</span>
      <Slider
        max={100}
        value={seek}
        onChange={(e, value) => {
          if (!seeking) {
            setSeeking(true);
          }
          setPlayed(Math.ceil((value / 100) * duration));
          setSeek(value);
        }}
        onChangeCommitted={() => {
          setPlayed(Math.ceil((seek / 100) * duration));
          _player.current.seekTo(parseFloat((seek / 100) * duration));
          setSeeking(false);
        }}
      />
      <span className="px-5 text-sm">{parsingSeconds(duration)}</span>
    </div>
  );
}
