import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import Container from "../components/AuthPages/Container";
import Header, { Heading } from "../components/AuthPages/Header";
import PackageOption from "../components/PackageOption";
import service from "../service";

export default function Package() {
  const [packages, setPackages] = useState([{}, {}, {}, {}]);
  const history = useHistory();
  const proceed = (id) => {
    service
      .post("/subscription/" + id)
      .then((response) => {
        const { id } = response.data;
        history.replace({ pathname: `/payment/${id}` });
      })
      .catch(() => {});
  };

  useEffect(() => {
    service
      .get("/subscription/package")
      .then((response) => {
        setPackages(response.data);
      })
      .catch((e) => {});
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Pilih Paket</title>
      </Helmet>
      <Container>
        <div className="w-full m-5 md:w-1/2 bg-white-100 dark:bg-dark-100 p-5 lg:p-8">
          <Header>
            <Heading
              title="Pilih Paket"
              subtitle="Anda belum berlangganan paket baring, silahkan pilih paket dibawah untuk mulai berlangganan"
            />
          </Header>
          {packages.map((value, index) => (
            <PackageOption
              key={`${index}`}
              title={value.title}
              subtitle={value.description}
              price={value.price}
              onClick={() => proceed(value.id)}
            />
          ))}
        </div>
      </Container>
    </Fragment>
  );
}
