import { createSlice, current } from "@reduxjs/toolkit";
import service from "../../service";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
  },
  reducers: {
    addItem: (state, { payload }) => {
      const currentItems = [...current(state).items];
      const findIndex = currentItems.findIndex((el) => el.id === payload.id);
      if (findIndex >= 0) {
        currentItems[findIndex] = {
          ...currentItems[findIndex],
          qty: currentItems[findIndex].qty + payload.qty,
        };
      } else {
        currentItems.push(payload);
      }

      service
        .post(
          "/cart/increase",
          payload.type === "product"
            ? {
                product_id: payload.id,
                qty: payload.qty,
              }
            : {
                course_id: payload.id,
              }
        )
        .then(() => {})
        .catch(() => {});

      return { ...state, items: currentItems };
    },
    decreaseQty: (state, { payload: { index } }) => {
      const currentItems = [...current(state).items];
      service
        .post("/cart/decrease", {
          product_id: currentItems[index].id,
          qty: 1,
        })
        .then(() => {})
        .catch(() => {});

      if (currentItems[index].qty < 2) {
        currentItems.splice(index, 1);
      } else {
        currentItems[index] = {
          ...currentItems[index],
          qty: currentItems[index].qty - 1,
        };
      }

      return { ...state, items: currentItems };
    },
    increaseQty: (state, { payload: { index } }) => {
      const currentItems = [...current(state).items];
      currentItems[index] = {
        ...currentItems[index],
        qty: currentItems[index].qty + 1,
      };

      service
        .post("/cart/increase", {
          product_id: currentItems[index].id,
          qty: 1,
        })
        .then(() => {})
        .catch(() => {});

      return { ...state, items: currentItems };
    },
    resetQty: (state, { payload: { index, qty } }) => {
      const currentItems = [...current(state).items];
      const id = currentItems[index].id;
      const type = currentItems[index].type;
      if (qty === 0) {
        currentItems.splice(index, 1);
      } else {
        currentItems[index] = {
          ...currentItems[index],
          qty: qty,
        };
      }

      service
        .post(
          "/cart/reset",
          type === "product"
            ? {
                product_id: id,
                qty: qty,
              }
            : {
                course_id: id,
              }
        )
        .then(() => {})
        .catch(() => {});

      return { ...state, items: currentItems };
    },
    reset: (state, { payload }) => {
      return { ...state, items: payload };
    },
    resetNotSync: (state) => {
      return { ...state, items: [] };
    },
  },
});

export const {
  addItem,
  increaseQty,
  decreaseQty,
  resetQty,
  reset,
  resetNotSync,
} = cartSlice.actions;

export default cartSlice.reducer;
