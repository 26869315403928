import { useState } from "react";
import { BiCircle, BiMinus, BiPlus } from "react-icons/bi";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

export default function SubjectList({ children, childs, id, member = false }) {
  const [hide, setHide] = useState(true);
  if (!children) {
    return (
      <div className="bg-white-100 flex items-center w-full mb-3 p-3">
        <div className="text-lg w-8 mr-3">
          <Skeleton />
        </div>
        <div className="text-base w-full">
          <Skeleton />
        </div>
      </div>
    );
  }
  return (
    <div className="w-full">
      <Link
        to={"/course/subject/" + id}
        className="bg-white-100 flex items-center w-full mb-3 py-3"
        type="button"
        onClick={(e) => {
          if (!member) {
            e.preventDefault();
          }
        }}
      >
        {childs?.length > 0 ? (
          <div className="w-12 h-8 flex justify-center items-center">
            <button
              onClick={(e) => {
                e.preventDefault();
                setHide((value) => !value);
              }}
              className="w-8 h-8 flex justify-center items-center hover:bg-white-300 rounded-full"
            >
              {hide ? <BiPlus /> : <BiMinus />}
            </button>
          </div>
        ) : (
          <div className="w-12 h-8 flex justify-center items-center">
            <BiCircle size={12} />
          </div>
        )}
        <div className="flex-1">{children}</div>
      </Link>
      {childs?.length > 0 ? (
        <div className={hide ? "hidden" : "flex"}>
          <div className="w-12" />
          <div className="flex-1">
            {childs?.map((item, index) => (
              <SubjectList
                childs={item.childs}
                key={`${index}`}
                id={item.id}
                member={member}
              >
                {item.title}
              </SubjectList>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
