import Skeleton from "react-loading-skeleton";
import { Link, useHistory } from "react-router-dom";

export default function BabList({
  ring,
  title,
  id,
  active,
  withReplace = false,
}) {
  let Comp = Link;
  const props = {};
  const history = useHistory();
  if (withReplace) {
    props.onClick = () => {
      history.replace(`/read/${id}`);
    };
    Comp = "button";
  } else {
    props.to = `/read/${id}`;
  }
  return (
    <Comp
      className={
        "p-5 rounded-sm block text-left hover:shadow-sm mb-3 bg-white-100 dark:bg-dark-100 border w-full " +
        (active ? "border-secondary-100" : "border-transparent")
      }
      {...props}
    >
      <span className="block text-black-300 text-sm">
        {ring ? (
          <>Ring {ring}</>
        ) : (
          <div className="w-24">
            <Skeleton />
          </div>
        )}
      </span>
      <h4 className="font-bold">{title || <Skeleton />}</h4>
    </Comp>
  );
}
