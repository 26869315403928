import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { FaFileDownload } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import Boost from "../../components/Boost";
import Header from "../../components/Header";
import MainContainer, {
  ColumnOne,
  ColumnTwo,
} from "../../components/MainContainer";
import SubjectList from "../../components/SubjectList";
import Button from "../../components/Button";

export default function WithChild({ data = {}, boosts }) {
  return (
    <MainContainer>
      <Helmet>{data.title && <title>{data.title}</title>}</Helmet>
      <ColumnOne>
        <ul className="breadcrumb">
          <li>
            {data.title ? (
              <Link to="/home">Home</Link>
            ) : (
              <div className="w-20">
                <Skeleton />
              </div>
            )}
          </li>
          <li>
            {data.title ? (
              <Link to="/course">Online Course</Link>
            ) : (
              <div className="w-20">
                <Skeleton />
              </div>
            )}
          </li>
          {data?.course ? (
            <li>
              <Link to={`/course/${data.course?.id}`}>
                {data.course?.title}
              </Link>
            </li>
          ) : null}
        </ul>
        <Header>
          {data.title ? (
            <Fragment>{data.title}</Fragment>
          ) : (
            <div className="w-2/3">
              <Skeleton />
            </div>
          )}
        </Header>
        <div className="mt-10 pb-8">
          {data.body ? (
            <Fragment>
              <div className="mb-3 pb-3">
                {data.video ? (
                  <div className="mb-4">
                    <ReactPlayer
                      url={data.video}
                      controls
                      width="100%"
                      height="auto"
                      config={{
                        file: {
                          attributes: {
                            controlsList: "nodownload",
                          },
                        },
                      }}
                    />
                  </div>
                ) : null}
                {data.audio || data.pdf ? (
                  <div className="flex mb-4 items-center">
                    {data.audio ? (
                      <div className="mr-2 flex-1">
                        <ReactPlayer
                          url={process.env.REACT_APP_BASE_URL + data.audio}
                          controls
                          width="100%"
                          height={50}
                          config={{
                            file: {
                              attributes: {
                                controlsList: "nodownload",
                              },
                            },
                          }}
                        />
                      </div>
                    ) : null}
                    {data.pdf ? (
                      <Button
                        rawLink="true"
                        link={true}
                        href={process.env.REACT_APP_BASE_URL + data.pdf}
                      >
                        <FaFileDownload /> PDF
                      </Button>
                    ) : null}
                  </div>
                ) : null}
                <div className={"transition-all duration-200 gotham-book"}>
                  <div
                    className="text-black-200 dark:text-white-300 text-justify noreset"
                    dangerouslySetInnerHTML={{ __html: data.body }}
                  ></div>
                </div>
              </div>
            </Fragment>
          ) : (
            <Skeleton count={8} />
          )}
        </div>
        <Boost boosted={data.is_boosted} boosts={boosts} id={data.id} />
      </ColumnOne>
      <ColumnTwo>
        <h2 className="poppins font-bold mb-5">Daftar Sub Materi</h2>
        {data && data.childs?.length < 1 ? (
          <div className="bg-white-100 p-3 text-center">Belum ada materi</div>
        ) : null}
        {(!data.childs ? [{}, {}, {}] : data?.childs).map((item, index) => (
          <SubjectList
            key={`${index}`}
            childs={item.childs}
            id={item.id}
            member={data.is_member}
          >
            {item.title}
          </SubjectList>
        ))}
      </ColumnTwo>
    </MainContainer>
  );
}
