import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import service from "../service";

export default function Logout() {
  const history = useHistory();
  const location = useLocation();
  const [cookies] = useCookies();
  const removeCookies = useCookies()[2];

  useEffect(() => {
    let state = { from: { pathname: location.state?.prevPath || "/" } };
    if (cookies.token) {
      service
        .delete("/auth/logout")
        .then(() => {
          removeCookies("id", {
            path: "/",
          });
          if (cookies.subscriber) {
            removeCookies("subscriber", {
              path: "/",
            });
          }
          removeCookies("token", {
            path: "/",
          });
          removeCookies("logo", {
            path: "/",
          });
          history.replace("/auth/login", state);
        })
        .catch((e) => {});
    }
  }, []);

  return null;
}
