import { Link } from "react-router-dom";

export default function Button({
  className,
  children,
  color,
  link = false,
  rawLink = false,
  ...props
}) {
  let colorCss = "bg-secondary-100 text-black-100";
  if (color === "red") {
    colorCss = "bg-red-100 text-white-100";
  } else if (color === "black") {
    colorCss = "bg-black-100 text-white-100";
  } else if (color === "white") {
    colorCss = "bg-white-100 text-black-200";
  }
  let ButtonEl = "button";
  if (link) {
    ButtonEl = Link;
    if (rawLink) {
      ButtonEl = "a";
    }
  }
  return (
    <ButtonEl
      className={
        colorCss +
        " font-bold poppins inline-flex justify-center items-center h-8 p-5 box-border " +
        className
      }
      {...props}
    >
      {children}
    </ButtonEl>
  );
}
