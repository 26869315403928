import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import CourseList from "../components/CourseList";
import Header from "../components/Header";
import NotFound from "../components/NotFound";
import { setPath } from "../redux/reducers/interface";
import service from "../service";

export default function Course() {
  const dispatch = useDispatch();
  const [data, setData] = useState([{}, {}, {}, {}, {}]);

  const _getData = (page = 1, reset = false) => {
    service
      .get("/course")
      .then((response) => {
        if (reset) {
          setData(response.data.data);
        } else {
          setData((value) => value.concat(response.data.data));
        }
      })
      .catch((e) => {
        setData([]);
      });
  };

  useEffect(() => {
    dispatch(setPath("/course"));
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    _getData(1, true);
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Online Course</title>
      </Helmet>
      <div className="p-5 lg:p-8">
        <Header>Online Course</Header>
        {data.length > 0 ? (
          <div className="grid grid-flow-row grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 lg:gap-8 mt-8 pb-8 box-border">
            {data.map((item, index) => (
              <CourseList
                key={`${index}`}
                id={item.id}
                title={item.title}
                price={item.price}
                cover={item.cover}
                mentor={item.users && item.users[0]?.fullname}
              />
            ))}
          </div>
        ) : (
          <NotFound />
        )}
      </div>
    </Fragment>
  );
}
