import { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { GoLink } from "react-icons/go";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import AuthRoute from "./components/AuthRoute";
import PrivateRoute from "./components/PrivateRoute";
import Account from "./pages/Account";
import Category from "./pages/Category";
import Checkout from "./pages/Checkout";
import Course from "./pages/Course";
import CourseDetail from "./pages/CourseDetail";
import Detail from "./pages/Detail";
import Ecommerce from "./pages/ecommerce/Ecommerce";
import ProductDetail from "./pages/ecommerce/ProductDetail";
import Template from "./pages/ecommerce/Template";
import Explore from "./pages/Explore";
import ForgotPassword from "./pages/ForgotPassword";
import Homepage from "./pages/Homepage";
import Liked from "./pages/Liked";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Package from "./pages/Package";
import Payment from "./pages/Payment";
import Read from "./pages/Read";
import Register from "./pages/Register";
import ResetPassword from "./pages/ResetPassword";
import SubjectDetail from "./pages/subject-detail";
import { reset } from "./redux/reducers/cart";
import service from "./service";

export default function App() {
  const [loading, setLoading] = useState(true);
  const [splash, setSplash] = useState(false);
  const [splashHide, setSplashHide] = useState(true);
  const [cookies] = useCookies();
  const removeCookies = useCookies()[2];
  const dispatch = useDispatch();
  const [showSub, setShowSub] = useState(false);

  const _getCart = () => {
    service
      .get("/cart")
      .then((response) => {
        const { data } = response;
        dispatch(reset(data));
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (cookies.token) {
      setSplash(true);
      service.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${cookies.token}`;
      _getCart();
      if (loading) {
        service
          .get("/auth/user/verify-token")
          .then((response) => {
            if (response.data.in_trial) {
              setShowSub(true);
            }
          })
          .catch((e) => {
            if (e.response?.status === 401) {
              removeCookies("id", {
                path: "/",
              });
              if (cookies.subscriber) {
                removeCookies("subscriber", {
                  path: "/",
                });
              }
              removeCookies("token", {
                path: "/",
              });
            }
          });
      }
    }

    setLoading(false);
  }, [cookies.token]);

  useEffect(() => {
    if (splash) {
      setSplashHide(false);
      const timeout = setTimeout(() => {
        setSplashHide(true);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [splash]);

  useEffect(() => {
    if (splashHide && splash) {
      setSplash(false);
    }
  }, [splashHide]);

  if (loading) return null;
  return (
    <Fragment>
      <Router>
        <Switch>
          <PrivateRoute path="/" exact>
            <Redirect to="/home" />
          </PrivateRoute>
          <PrivateRoute path="/home">
            <Homepage />
          </PrivateRoute>
          <PrivateRoute path="/explore">
            <Explore />
          </PrivateRoute>
          <PrivateRoute path="/liked">
            <Liked />
          </PrivateRoute>
          <PrivateRoute path="/ecommerce/product/:id">
            <Template>
              <ProductDetail />
            </Template>
          </PrivateRoute>
          <PrivateRoute path="/ecommerce">
            <Template>
              <Ecommerce />
            </Template>
          </PrivateRoute>
          <PrivateRoute path="/payment/:id">
            <Payment />
          </PrivateRoute>
          <PrivateRoute path="/checkout">
            <Checkout />
          </PrivateRoute>
          <PrivateRoute path="/course/subject/:id">
            <SubjectDetail />
          </PrivateRoute>
          <PrivateRoute path="/course/:id">
            <CourseDetail />
          </PrivateRoute>
          <PrivateRoute path="/course">
            <Course />
          </PrivateRoute>
          <PrivateRoute path="/category/:id">
            <Category />
          </PrivateRoute>
          <PrivateRoute path="/account/:page">
            <Account />
          </PrivateRoute>
          <PrivateRoute path="/detail/:id">
            <Detail />
          </PrivateRoute>
          <PrivateRoute path="/read/:id">
            <Read />
          </PrivateRoute>
          <PrivateRoute path="/subscription">
            <Package />
          </PrivateRoute>
          <PrivateRoute path="/logout">
            <Logout />
          </PrivateRoute>

          <Route path="/auth/login">
            <AuthRoute>
              <Login />
            </AuthRoute>
          </Route>
          <Route path="/auth/reset-pass">
            <AuthRoute>
              <ResetPassword />
            </AuthRoute>
          </Route>
          <Route path="/auth/register">
            <AuthRoute>
              <Register />
            </AuthRoute>
          </Route>
          <Route path="/auth/forgot-password">
            <AuthRoute>
              <ForgotPassword />
            </AuthRoute>
          </Route>
        </Switch>
        {showSub && cookies.token ? (
          <div className="z-40 h-screen w-screen bg-black-100 bg-opacity-60 fixed top-0 left-0 flex justify-center items-center">
            <div className="bg-white-100 w-full lg:w-1/3 m-5 p-5 flex flex-col">
              <GoLink size={32} />
              <div className="text-lg poppins font-bold mt-3">
                Ayo Berlangganan
              </div>
              <div className="text-black-200">
                Anda belum berlangganan paket baring
              </div>
              <div className="mt-8 grid grid-flow-col grid-cols-2 gap-5">
                <button
                  type="button"
                  onClick={() => {
                    setShowSub(false);
                  }}
                  className="bg-white-300 p-2 px-5 text-center"
                >
                  Lanjutkan Trial
                </button>
                <Link
                  to="/subscription"
                  onClick={() => {
                    setShowSub(false);
                  }}
                  className="bg-primary-100 text-white-100 p-2 px-5 text-center"
                >
                  Berlangganan
                </Link>
              </div>
            </div>
          </div>
        ) : null}
      </Router>
      {splash ? (
        <div
          className={
            "z-50 h-screen w-screen bg-primary-100 flex justify-center items-center transition-all duration-300 fixed top-0 left-0 " +
            (splashHide ? "opacity-0" : "opacity-100")
          }
        >
          <img
            src={cookies.logo}
            className="object-contain object-center w-32 h-32 lg:w-64 lg:h-64"
          />
        </div>
      ) : null}
    </Fragment>
  );
}
