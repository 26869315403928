import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { GoBook } from "react-icons/go";
import Skeleton from "react-loading-skeleton";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import BabList from "../components/BabList";
import Header from "../components/Header";
import MainContainer, {
  ColumnOne,
  ColumnTwo,
} from "../components/MainContainer";
import Player from "../components/Player";
import service from "../service";

export default function Read() {
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState({});
  const [index, setIndex] = useState(0);
  const { state: { autoplay } = {} } = useLocation();

  const _getData = () => {
    service
      .get("/bab/" + id + "/read")
      .then((response) => {
        const { data } = response;
        const index = data.content?.babs?.findIndex((el) => {
          return el.id === data.id;
        });
        setData(data);
        setIndex(index + 1);
      })
      .catch((e) => {
        if (e.response?.status === 404) {
          history.replace("/");
        } else if (e.response?.status === 405) {
          history.replace("/subscription");
        }
      });
  };

  useEffect(() => {
    setData({});
    setIndex(0);
    _getData();
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    const contextmenuListener = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", contextmenuListener);

    return () => {
      document.removeEventListener("contextmenu", contextmenuListener);
    };
  }, []);

  return (
    <MainContainer className="flex-wrap mb-12 lg:mb-0">
      <Helmet>
        {data.content?.title && (
          <title>
            {`${index}/${data.content?.babs?.length} - ${data.content?.title}`}
          </title>
        )}
      </Helmet>
      <ColumnOne className="bg-white-100 dark:bg-dark-100 flex-grow-0 w-full">
        <ul className="breadcrumb">
          <li>
            {data.content ? (
              <Link to="/home">Home</Link>
            ) : (
              <div className="w-20">
                <Skeleton />
              </div>
            )}
          </li>
          <li>
            {data.content ? (
              <Link to={`/detail/${data.content?.id}`}>
                {data.content?.title}
              </Link>
            ) : (
              <div className="w-20">
                <Skeleton />
              </div>
            )}
          </li>
        </ul>
        <Header>
          {data.title || (
            <div className="w-2/3">
              <Skeleton />
            </div>
          )}
        </Header>
        {!data.body ? (
          <Fragment>
            <div className="mt-8">
              <Skeleton count={5} />
            </div>
            <div className="mt-6">
              <Skeleton count={4} />
            </div>
          </Fragment>
        ) : (
          <div
            className="noreset mt-8 gotham-book"
            dangerouslySetInnerHTML={{ __html: data.body }}
          ></div>
        )}
      </ColumnOne>
      <ColumnTwo className="w-full">
        <Link
          to={data.content ? "/detail/" + data.content?.id : "#"}
          className="bg-white-100 dark:bg-dark-100 p-2 lg:p-4 flex border border-transparent hover:border-white-400 dark:hover:border-black-100 rounded-sm mb-5"
        >
          <div className="w-16">
            <div className="w-1-1">
              {data.content?.cover ? (
                <img
                  src={process.env.REACT_APP_BASE_URL + data.content?.cover}
                  className="object-cover"
                  alt="Buku 1"
                />
              ) : (
                <div />
              )}
            </div>
          </div>
          <div className="text-container flex-1 ml-4 overflow-hidden">
            <h2 className="text poppins font-bold nl-2">
              {data.content?.title || <Skeleton />}
            </h2>
            {data.content?.babs ? (
              <div className="flex items-center text-sm mt-1">
                <GoBook className="mr-1" />
                {data.content?.babs?.length} Ring
              </div>
            ) : (
              <div className="text-sm w-20 mt-1">
                <Skeleton />
              </div>
            )}
          </div>
        </Link>
        {(data.content?.babs ? data.content?.babs : [{}, {}, {}, {}]).map(
          (item, index) => (
            <BabList
              active={item.id ? item.id === data.id : false}
              id={item.id}
              ring={
                data.content?.babs
                  ? `${index + 1}/${data.content?.babs?.length}`
                  : null
              }
              title={item.title}
              key={`${index}`}
              withReplace={true}
            />
          )
        )}
      </ColumnTwo>
      {data.id && (
        <div className="w-full bg-white-100 dark:bg-dark-100 h-12 border-t border-white-500 lg:flex-grow fixed lg:static bottom-0 left-0 mb-16 lg:mb-0 dark:border-black-100">
          <Player
            autoplay={autoplay}
            handleNext={() => {
              if (index < data.content?.babs.length) {
                history.replace(`/read/${data.content?.babs[index]?.id}`, {
                  autoplay: true,
                });
              }
            }}
            handlePrev={() => {
              if (index > 1) {
                history.replace(`/read/${data.content?.babs[index - 2]?.id}`, {
                  autoplay: true,
                });
              }
            }}
            handleEnd={() => {
              if (index < data.content?.babs.length) {
                history.replace(`/read/${data.content?.babs[index]?.id}`, {
                  autoplay: true,
                });
              }
            }}
            url={process.env.REACT_APP_BASE_URL + data.audio}
          />
        </div>
      )}
    </MainContainer>
  );
}
