import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";

export default function AuthRoute({ children }) {
  const history = useHistory();
  const location = useLocation();
  const [cookies] = useCookies();

  useEffect(() => {
    if (cookies.token) {
      history.replace(location.state?.from || { pathname: "/" });
    }
  }, [cookies.token, history, location.state]);

  return children;
}
