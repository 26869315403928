import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as CartIcon } from "../icons/Cart.svg";
import { ReactComponent as CloseIcon } from "../icons/Close.svg";
import { ReactComponent as GraduationIcon } from "../icons/Graduation.svg";
import { ReactComponent as HomeIcon } from "../icons/Home.svg";
import { ReactComponent as MenuIcon } from "../icons/Menu.svg";
import { ReactComponent as SearchIcon } from "../icons/Search.svg";
import { ReactComponent as UserIcon } from "../icons/User.svg";
import { ReactComponent as LogoutIcon } from "../icons/Logout.svg";
import { useCookies } from "react-cookie";

const List = ({
  icon,
  children,
  className,
  button = false,
  path = "/",
  ...props
}) => {
  const activePath = useSelector((state) => state.interface.activePath);
  const Icon = icon;
  const active = path === activePath ? true : false;
  const LinkItem = !button ? Link : "button";
  if (!button) {
    props.to = path;
  }
  return (
    <LinkItem
      className={
        "flex-1 lg:flex-initial flex justify-center lg:justify-start items-center poppins text-white-400 overflow-x-hidden hover:bg-primary-200 hover:text-white-100 border-l-0 border-t-2 lg:border-l-2 lg:border-t-0 " +
        (active
          ? "bg-primary-200 border-secondary-100"
          : "bg-primary-100 border-transparent") +
        " " +
        className
      }
      {...props}
    >
      <span className="text-white-300 w-16 h-12 flex justify-center items-center box-border flex-shrink-0 mb-1">
        <Icon width={20} height={20} />
      </span>
      <span className="hidden lg:block whitespace-nowrap">{children}</span>
    </LinkItem>
  );
};

export default function Sidebar() {
  const [show, setShow] = useState(false);
  const [cookies] = useCookies();
  return (
    <div
      className={
        "z-20 h-16 lg:h-screen w-full " +
        (show ? "lg:w-64" : "lg:w-16") +
        " fixed top-auto bottom-0 lg:top-0 lg:bottom-auto left-0 bg-primary-100 transition-all duration-300 overflow-y-hidden flex flex-row lg:flex-col justify-center"
      }
    >
      <div className="flex-1 lg:flex-initial justify-center lg:justify-start items-center poppins text-white-400 overflow-x-hidden hover:bg-primary-200 hover:text-white-100 border-l-0 border-t-2 lg:border-l-2 lg:border-t-0 mb-auto hidden lg:flex bg-primary-100 border-transparent">
        <img src={cookies.logo} className="w-3/4 h-auto mx-auto mt-2" />
      </div>
      <List
        onClick={() => {
          setShow(false);
        }}
        icon={HomeIcon}
        path="/home"
      >
        Home
      </List>
      <List
        onClick={() => {
          setShow(false);
        }}
        icon={SearchIcon}
        path="/explore"
      >
        Jelajahi
      </List>
      <List
        onClick={() => {
          setShow(false);
        }}
        icon={GraduationIcon}
        path="/course"
      >
        Online Course
      </List>
      <List
        onClick={() => {
          setShow(false);
        }}
        icon={CartIcon}
        path="/ecommerce"
      >
        Ecommerce
      </List>
      <List
        onClick={() => {
          setShow(false);
        }}
        icon={UserIcon}
        path="/account/information"
      >
        Akun
      </List>
      <List
        icon={LogoutIcon}
        onClick={() => {
          setShow(false);
        }}
        path={{
          pathname: "/logout",
          state: { prevPath: window.location.pathname },
        }}
        className="mt-auto hidden lg:flex"
      >
        Keluar
      </List>
    </div>
  );
}
