import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { BiHeart } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ContentList from "../components/ContentList";
import NotFound from "../components/NotFound";
import useDebounce from "../debounce";
import { setPath } from "../redux/reducers/interface";
import service from "../service";

export default function Explore() {
  const dispatch = useDispatch();
  const [data, setData] = useState([{}, {}, {}, {}, {}]);
  const [query, setQuery] = useState("");
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const debounce = useDebounce((text) => _getData(text, 1, true), 2000);

  const _getData = (query = "", page = 1) => {
    setData([{}, {}, {}, {}, {}]);
    service
      .get("/content", {
        params: {
          page,
          q: query,
        },
      })
      .then((response) => {
        const { data, total } = response.data;
        setData(data);
        setTotal(total);
      })
      .catch((e) => {
        setData([]);
        setTotal(0);
      });
  };

  useEffect(() => {
    dispatch(setPath("/explore"));
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    _getData(query, page);
  }, [page]);
  return (
    <Fragment>
      <Helmet>
        <title>Jelajah</title>
      </Helmet>
      <div className="p-5 lg:p-8">
        <div className="flex justify-center mb-10">
          <div className="bg-white-100 w-full md:w-2/3 flex items-center">
            <input
              className="h-12 flex-1 px-5 outline-none"
              placeholder="Cari judul atau penulis disini..."
              onChange={(e) => {
                setData([{}, {}, {}, {}, {}]);
                debounce(e.target.value);
                setQuery(e.target.value);
              }}
            />
            <span className="text-black-200 mx-5">
              <FaSearch size={20} />
            </span>
          </div>
        </div>
        {data.length > 0 ? (
          <Fragment>
            <div className="grid grid-flow-row grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 lg:gap-8 mt-8 pb-8 box-border">
              {data.map((item, index) => (
                <ContentList
                  cover={item.cover}
                  title={item.title}
                  bab={item.babs_count}
                  authors={item.authors}
                  key={index}
                  id={item.id}
                  isLiked={item.is_liked}
                />
              ))}
            </div>
            {total > 1 ? (
              <ReactPaginate
                pageCount={total}
                pageRangeDisplayed={6}
                marginPagesDisplayed={1}
                initialPage={page - 1}
                containerClassName="flex flex-wrap mt-8"
                previousLinkClassName="m-1 border border-white-400 py-1 px-3 hover:bg-primary-100 hover:border-primary-200 hover:text-white-100 block"
                nextLinkClassName="m-1 border border-white-400 py-1 px-3 hover:bg-primary-100 hover:border-primary-200 hover:text-white-100 block"
                pageLinkClassName="m-1 border border-white-400 py-1 px-3 hover:bg-primary-100 hover:border-primary-200 hover:text-white-100 block"
                activeLinkClassName="bg-primary-100 border-primary-200 text-white-100"
                onPageChange={({ selected }) => setPage(selected + 1)}
              />
            ) : null}
          </Fragment>
        ) : (
          <NotFound />
        )}
      </div>
    </Fragment>
  );
}
