import { forwardRef, useImperativeHandle, useState } from "react";

const Loader = ({ className, defaultShow = false, placeholder }, ref) => {
  const [show, setShow] = useState(defaultShow);

  useImperativeHandle(
    ref,
    () => ({
      show: () => {
        setShow(true);
      },
      hide: () => {
        setShow(false);
      },
      status: () => {
        return show;
      },
    }),
    [show]
  );

  if (!show) {
    return placeholder;
  }

  return (
    <div className="loader">
      <div className={className}></div>
      <div className={className}></div>
      <div className={className}></div>
    </div>
  );
};

export default forwardRef(Loader, []);
