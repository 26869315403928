import { Fragment, useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import Header from "../components/Header";
import { setPath } from "../redux/reducers/interface";
import service from "../service";
import ContentList from "../components/ContentList";
import NotFound from "../components/NotFound";

export default function Liked() {
  const dispatch = useDispatch();
  const [data, setData] = useState([{}, {}, {}, {}, {}]);

  const _getData = (page = 1, reset = false) => {
    service
      .get("/content", {
        params: {
          page,
          liked: 1,
        },
      })
      .then((response) => {
        const { data } = response.data;
        if (reset) {
          setData(data);
        } else {
          setData((value) => value.concat(data));
        }
      })
      .catch((e) => {
        setData([]);
      });
  };

  useEffect(() => {
    dispatch(setPath("/explore"));
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    _getData(1, true);
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Konten Disukai</title>
      </Helmet>
      <div className="p-5 lg:p-8">
        <Header>Konten Disukai</Header>
        {data.length > 0 ? (
          <div className="grid grid-flow-row grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 lg:gap-8 mt-8 pb-8 box-border">
            {data.map((item, index) => (
              <ContentList
                cover={item.cover}
                title={item.title}
                bab={item.babs_count}
                authors={item.authors}
                key={index}
                id={item.id}
                isLiked={item.is_liked}
                onUnlike={() => {
                  setData((value) => {
                    const temp = value;
                    temp.splice(index, 1);
                    return [...temp];
                  });
                }}
              />
            ))}
          </div>
        ) : (
          <NotFound />
        )}
      </div>
    </Fragment>
  );
}
