import { forwardRef } from "react";

const TextInput = (
  { label, className, containerClassName, message, ...props },
  ref
) => {
  return (
    <div className={containerClassName}>
      {label && (
        <label className="text-black-200 dark:text-white-500 mb-1 roboto block">
          {label}
        </label>
      )}
      <input
        className={
          "h-10 bg-white-100 dark:bg-dark-100 border border-white-400 dark:border-black-200 rounded-none block w-full text-black-200 dark:text-white-500 roboto py-1 px-3 box-border " +
          className
        }
        ref={ref}
        {...props}
      />
      {message && <div className="text-red-100 text-sm roboto">{message}</div>}
    </div>
  );
};

export default forwardRef(TextInput, []);
