export default function CustomAlert({
  type = "danger",
  success = false,
  className,
  children,
}) {
  let style = "text-white-100";
  if (!success) {
    style += " bg-red-100";
  } else {
    style += " bg-green-100";
  }

  return <div className={`p-3 roboto ${style} ${className}`}>{children}</div>;
}
