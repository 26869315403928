import { Fragment } from "react";

export default function Header({ children }) {
  return (
    <Fragment>
      <h1 className="text-xl font-bold gotham-bold text-black-100">
        {children}
      </h1>
      <div className="bg-secondary-100 h-1 w-24 mt-2" />
    </Fragment>
  );
}
