import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Route, useHistory, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";

const Template = ({ children }) => {
  return (
    <div className="bg-white-200 dark:bg-dark-200 min-h-screen w-full pl-0 lg:pl-16 pb-16 lg:pb-0 text-black-100 dark:text-white-400">
      <Sidebar />
      {children}
    </div>
  );
};

export default function PrivateRoute({
  children,
  isNeedPackage = false,
  ...props
}) {
  const [cookies] = useCookies();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let state = { from: { pathname: location.pathname || "/" } };
    if (!cookies.token) {
      history.replace("/auth/login", state);
    }
  }, [cookies.token, history, location.pathname]);

  return (
    <Route {...props}>
      <Template>{children}</Template>
    </Route>
  );
}
