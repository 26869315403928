import moment from "moment";
import "moment/locale/id";
import { Fragment } from "react";
import { GoStar } from "react-icons/go";
import Skeleton from "react-loading-skeleton";

moment.locale("id");

export default function ReviewList({ fullname, date, star = 0, children }) {
  return (
    <div className="bg-white-100 p-5 mt-5">
      <div className="border-b border-white-300 pb-5 mb-5">
        {fullname ? (
          <h5 className="font-bold">{fullname}</h5>
        ) : (
          <div className="w-3/4 lg:w-1/2">
            <Skeleton />
          </div>
        )}
        {date ? (
          <span className="text-sm">
            {moment(date).format("dddd, D MMM YYYY")}
          </span>
        ) : (
          <div className="text-sm w-1/2 lg:w-1/3">
            <Skeleton />
          </div>
        )}
      </div>
      {children ? (
        <Fragment>
          <div className="mb-3 text-lg flex">
            {[1, 2, 3, 4, 5].map((item, index) => (
              <span
                className={`mr-3 ${
                  star >= item ? "text-secondary-100" : "text-black-300"
                }`}
                key={`${index}`}
              >
                <GoStar />
              </span>
            ))}
          </div>
        </Fragment>
      ) : (
        <Skeleton count={2} />
      )}
      {children}
    </div>
  );
}
