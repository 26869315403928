import { Fragment, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaCartPlus, FaMinus, FaPlus } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { ColumnOne } from "../../components/MainContainer";
import { addItem } from "../../redux/reducers/cart";
import service from "../../service";

export default function ProductDetail() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [detail, setDetail] = useState(false);
  const [value, setValue] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();

  const _getData = useCallback(() => {
    service
      .get(`/product/${id}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((e) => {
        if (e.response?.status === 404) {
          history.replace("/");
        }
      });
  }, [id, history]);

  useEffect(() => {
    window.scrollTo(0, 0);
    _getData();
  }, []);

  return (
    <ColumnOne>
      <Helmet>{data.title}</Helmet>
      <ul className="breadcrumb">
        <li>
          <Link to="/home">Home</Link>
        </li>
        <li>
          <Link to="/ecommerce">Ecommerce</Link>
        </li>
      </ul>
      <Header>
        {data.title || (
          <div className="w-2/3">
            <Skeleton />
          </div>
        )}
      </Header>
      <div className="flex flex-col md:flex-row mt-10 pb-8">
        <div className="w-1/2 md:w-1/3 lg:w-1/4 mx-auto md:mx-0">
          <div className="bg-white-100 dark:bg-dark-100 p-2 box-border">
            <div className="w-1-1 box-border">
              {data.cover ? (
                <img
                  alt={data.title}
                  src={process.env.REACT_APP_BASE_URL + data.cover}
                  className="object-cover"
                />
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
        <div className="ml-0 mt-8 md:ml-5 md:mt-0 flex-1">
          {data.title || data.description ? (
            <Fragment>
              <div
                className={
                  "transition-all duration-200 " +
                  (detail ? " max-h-full" : "sinopsis")
                }
              >
                <div className="text-black-200 dark:text-white-300 text-justify">
                  {data.description}
                </div>
              </div>
              <button
                type="button"
                className="mt-5 font-bold text-primary-200 dark:text-secondary-100"
                onClick={() => {
                  setDetail((value) => !value);
                }}
              >
                {detail ? "TUTUP" : "BACA SELENGKAPNYA"}
              </button>
              <div className="flex mt-5">
                <button
                  type="button"
                  onClick={() =>
                    setValue((value) => (value === 1 ? 1 : (value -= 1)))
                  }
                  className="h-10 w-10 bg-primary-100 text-white-100 rounded-sm flex justify-center items-center"
                >
                  <FaMinus size={12} />
                </button>
                <input
                  className="h-10 w-16 box-border mx-2 bg-white-100 dark:bg-dark-100 rounded-sm text-center"
                  type="text"
                  value={`${value}`}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  onBlur={(e) => {
                    const value = parseInt(e.target.value);
                    setValue(value || 1);
                  }}
                />
                <button
                  type="button"
                  onClick={() => setValue((value) => (value += 1))}
                  className="h-10 w-10 bg-primary-100 text-white-100 rounded-sm flex justify-center items-center"
                >
                  <FaPlus size={12} />
                </button>
                <Button
                  type="button"
                  className="ml-5"
                  onClick={() => {
                    dispatch(
                      addItem({
                        id: data.id,
                        name: data.title,
                        price: data.price,
                        qty: value,
                        image: data.cover,
                        type: "product",
                      })
                    );
                  }}
                >
                  <FaCartPlus className="mr-3" /> Keranjang
                </Button>
              </div>
            </Fragment>
          ) : (
            <Skeleton count={8} />
          )}
        </div>
      </div>
    </ColumnOne>
  );
}
