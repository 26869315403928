import { Fragment, useEffect, useState } from "react";
import { BiHeart } from "react-icons/bi";
import { GoBook } from "react-icons/go";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import service from "../service";
export default function ContentList({
  cover,
  title,
  bab,
  id,
  isLiked,
  authors = [],
  onUnlike = () => {},
  onLike = () => {},
}) {
  const [liked, setLiked] = useState(false);
  useEffect(() => {
    setLiked(isLiked);
  }, [isLiked]);
  return (
    <Link
      to={id ? `/detail/${id}` : "#"}
      onClick={(e) => {
        if (!id) {
          e.preventDefault();
        }
      }}
      className="relative bg-white-100 dark:bg-dark-100 p-3 hover:shadow-md group"
    >
      <div className="w-1-1 p-3">
        {cover ? (
          <img
            src={process.env.REACT_APP_BASE_URL + cover}
            className="object-cover"
            alt={title}
          />
        ) : (
          <div />
        )}
      </div>
      {cover && (
        <button
          onClick={(e) => {
            e.preventDefault();
            service
              .post(`/content/${id}/${liked ? "unlike" : "like"}`)
              .then(() => {})
              .catch(() => {});
            if (liked) {
              onUnlike();
            } else {
              onLike();
            }
            setLiked((value) => {
              return !value;
            });
          }}
          className={
            "absolute top-0 right-0 w-8 h-8 mt-5 mr-5 opacity-100 lg:opacity-0 group-hover:opacity-100 transition duration-300 flex justify-center items-center rounded-sm " +
            (liked
              ? "bg-red-100 text-white-100"
              : "bg-white-100 text-black-200")
          }
          type="button"
        >
          <BiHeart size={24} />
        </button>
      )}
      <div className="overflow-hidden text-container mt-3">
        {title ? <h3 className="text font-bold">{title}</h3> : <Skeleton />}
      </div>
      {authors.length > 0 ? (
        <div className="text-sm">
          {authors.map((item, index) => (
            <Fragment key={`content-author-${index}`}>
              {index > 0 &&
                (!authors.length > 2 || index === authors.length - 1) &&
                " dan "}
              {index > 0 &&
                !(!authors.length > 2 || index === authors.length - 1) &&
                ", "}
              {item.name}
            </Fragment>
          ))}
        </div>
      ) : (
        <div className="w-1/2 text-sm">
          <Skeleton />
        </div>
      )}
      {typeof bab !== "undefined" ? (
        <div className="flex items-center text-sm">
          <GoBook className="mr-1" />
          {bab} Ring
        </div>
      ) : (
        <div className="w-1/2 text-sm">
          <Skeleton />
        </div>
      )}
    </Link>
  );
}
