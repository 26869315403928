import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiSend } from "react-icons/bi";
import { FaPlus, FaTimes } from "react-icons/fa";
import service from "../service";
import BoostList from "./BoostList";

export default function Boost({ boosts = [], boosted, id }) {
  const [currBoosts, setCurrBoosts] = useState(boosts);
  const [boostCompose, setBoostCompose] = useState(false);
  const [boostSending, setBoostSending] = useState(false);
  const { register, handleSubmit } = useForm();
  const [isBoosted, setIsBoosted] = useState(false);

  const sendBoost = ({ body }) => {
    setBoostSending(true);
    service
      .post("/boost/" + id, {
        body,
      })
      .then((response) => {
        setBoostSending(false);
        setIsBoosted(true);
        setCurrBoosts((value) => {
          return [...value, response.data];
        });
      })
      .catch((e) => {
        setBoostSending(false);
      });
  };

  useEffect(() => {
    setIsBoosted(boosted);
  }, [boosted]);

  useEffect(() => {
    setCurrBoosts(boosts);
  }, [boosts]);

  return (
    <Fragment>
      {currBoosts.length > 0 ? (
        <div className="mt-10 flex flex-wrap">
          {currBoosts.map((item, index) => (
            <BoostList key={`${index}`} avatar={item.user?.avatar}>
              {item.body}
            </BoostList>
          ))}
        </div>
      ) : null}
      {isBoosted ? null : (
        <form className="mt-3" onSubmit={handleSubmit(sendBoost)}>
          <div
            className={`h-10 ${
              boostCompose ? "bg-white-100 w-full lg:w-1/2" : "w-20 bg-blue-100"
            } rounded-sm transition-all duration-300 flex items-center px-1`}
          >
            <button
              disabled={boostSending}
              onClick={() => setBoostCompose((value) => !value)}
              type="button"
              className={`h-8 ${
                boostCompose ? "w-8 bg-red-100" : "px-2"
              } flex justify-center items-center rounded-sm text-white-100 text-sm transition-all duration-300`}
            >
              {boostCompose ? (
                <FaTimes />
              ) : (
                <Fragment>
                  <FaPlus className="mr-1" /> Boost
                </Fragment>
              )}
            </button>
            <input
              disabled={boostSending}
              className={`${
                boostCompose ? "w-full flex-1" : "w-0"
              } transition-all duration-300 bg-transparent p-1 px-2`}
              type="text"
              placeholder="Tulis sesuatu..."
              {...register("body", { required: true })}
            />
            <button
              type="submit"
              className={`w-8 h-8 justify-center items-center text-blue-100 ${
                boostCompose && !boostSending ? "flex" : "hidden"
              }`}
            >
              <BiSend />
            </button>
          </div>
          {boostSending ? (
            <span className="text-sm text-black-300">Mengirim...</span>
          ) : null}
        </form>
      )}
    </Fragment>
  );
}
