import { FaUser } from "react-icons/fa";

export default function BoostList({ children, avatar }) {
  return (
    <div className="bg-white-100 p-1 flex items-center mr-3 mb-3">
      <div className="w-8 h-8 bg-white-300 flex justify-center items-center">
        <img
          src={process.env.REACT_APP_BASE_URL + avatar}
          className="w-full h-full object-contain"
        />
      </div>
      <span className="px-2 text-sm">{children}</span>
    </div>
  );
}
