import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import Header, { Heading } from "../components/AuthPages/Header";
import InnerContainer from "../components/AuthPages/InnerContainer";
import Button from "../components/Button";
import CustomAlert from "../components/CustomAlert";
import Loader from "../components/Loader";
import TextInput from "../components/TextInput";
import service from "../service";

export default function ForgotPassword() {
  const [fail, setFail] = useState(false);
  const [success, setSuccess] = useState(false);
  const { register, handleSubmit } = useForm();
  const _loadRef = useRef();

  const _proceed = ({ email }) => {
    _loadRef.current.show();
    service
      .post("/auth/user/reset-request", {
        url: process.env.REACT_APP_RESET_URL,
        email,
      })
      .then((response) => {
        setSuccess(true);
      })
      .catch((e) => {
        if (e?.response?.status === 404) {
          setFail(true);
        }
        _loadRef.current.hide();
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Lupa Password</title>
      </Helmet>
      <div className="bg-white-200 dark:bg-dark-200 min-h-screen w-full flex justify-center items-center">
        <InnerContainer>
          <Header />
          {success ? (
            <CustomAlert success={true}>
              Silahkan lanjutkan melalui link yang telah kami kirimkan ke email
              anda
            </CustomAlert>
          ) : (
            <Fragment>
              <Heading
                scheme="black"
                title="Lupa Password?"
                subtitle="Masukkan email atau username untuk mencari akun anda"
              />
              <form className="mt-8" onSubmit={handleSubmit(_proceed)}>
                {fail && (
                  <CustomAlert className="mb-4 text-center">
                    Email tidak ditemukan
                  </CustomAlert>
                )}
                <TextInput
                  type="text"
                  placeholder="Email"
                  containerClassName="mb-5"
                  {...register("email", { required: true })}
                />
                <Button type="submit" className="flex w-full">
                  <Loader
                    className="bg-black-100"
                    placeholder="Lanjutkan"
                    ref={_loadRef}
                  />
                </Button>
              </form>
            </Fragment>
          )}
        </InnerContainer>
      </div>
    </Fragment>
  );
}
