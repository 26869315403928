export default function CommentList({
  name,
  body,
  replies,
  onReply = () => {},
  inReplyPage = false,
  mentor,
}) {
  return (
    <div className="p-5 bg-white-100 mb-5">
      <div>
        <div className="font-bold text-black-200">
          {name}{" "}
          {mentor ? (
            <span className="text-red-100 font-normal">(Mentor)</span>
          ) : null}
        </div>
        <div className="text-black-300">{body}</div>
      </div>
      {inReplyPage ? null : (
        <div className="mt-3">
          <a
            className="text-blue-100 cursor-pointer text-sm"
            onClick={(e) => {
              e.preventDefault();
              onReply();
            }}
          >
            {replies && Number(replies) > 0 ? `${replies} Balasan` : "Balas"}
          </a>
        </div>
      )}
    </div>
  );
}
