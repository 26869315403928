import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Header from "../components/Header";
import { setPath } from "../redux/reducers/interface";
import service from "../service";
import Skeleton from "react-loading-skeleton";
import reactStringReplace from "react-string-replace";

export default function Payment() {
  const dispatch = useDispatch();
  const [bank, setBank] = useState([]);
  const [data, setData] = useState({});
  const { id } = useParams();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [text, setText] = useState("");

  const getBank = () => {
    return new Promise((resolve) => {
      service
        .get("/bank")
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve(false);
        });
    });
  };

  const getConfig = () => {
    return new Promise((resolve) => {
      service
        .get("/config")
        .then((response) => {
          const { paymentText } = response.data;
          resolve(paymentText);
        })
        .catch(() => {
          resolve(false);
        });
    });
  };

  const getData = (id) => {
    return new Promise((resolve) => {
      service
        .get(`/checkout/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          if (e.response?.status === 404) {
            history.replace("/");
          }
          resolve(false);
        });
    });
  };

  useEffect(() => {
    dispatch(setPath("/ecommerce"));
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      setData({});
      setBank([{}, {}, {}, {}]);
      const data = await getData(id);
      const bank = await getBank();
      const text = await getConfig();

      if (!data || !bank || !text) {
        setError(true);
        return;
      }

      setData(data);
      setBank(bank);
      setText(text);
    })();
  }, [id]);

  if (error) {
    return <div className="p-5 lg:p-8">Terjadi kesalahan</div>;
  }
  return (
    <div className="p-5 lg:p-8">
      <Helmet>
        <title>Pembayaran</title>
      </Helmet>
      <div className="w-full lg:w-1/2 mx-auto bg-white-100 p-5">
        <Header>Pembayaran</Header>
        <div className="bg-white-100 p-5 mt-8 flex flex-col border border-white-500">
          <span className="text-black-300">Total</span>
          <span className="font-bold text-xl">
            {data.total >= 0 ? (
              <NumberFormat
                value={data.total}
                prefix="Rp "
                displayType="text"
                thousandSeparator={true}
              />
            ) : (
              <Skeleton />
            )}
          </span>
        </div>
        <div className="pt-5">
          {reactStringReplace(text, "{bank}", () => (
            <div className="mt-4">
              {bank.map((item, index) => (
                <div
                  className="border-l-2 border-white-300 px-5 py-3 mb-4"
                  key={`${index}`}
                >
                  <div className="text-lg font-bold">
                    {item.bank_name || (
                      <div className="w-1/2 lg:w-1/3">
                        <Skeleton />
                      </div>
                    )}
                  </div>
                  {item.account_name ? (
                    <div>
                      {item.account_number} <br /> {item.account_name}
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
