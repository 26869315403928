import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import Button from "../components/Button";
import ContentList from "../components/ContentList";
import Header from "../components/Header";
import MainContainer, {
  ColumnOne,
  ColumnTwo,
} from "../components/MainContainer";
import { setPath } from "../redux/reducers/interface";
import service from "../service";

export default function Homepage() {
  const dispatch = useDispatch();
  const [data, setData] = useState([
    {
      contents: [{}, {}, {}, {}, {}, {}],
    },
    {
      contents: [{}, {}, {}, {}, {}, {}],
    },
  ]);
  const [tempLiked, setTempLiked] = useState([]);
  const [tempUnliked, setTempUnliked] = useState([]);

  const _getData = () => {
    service
      .get("/category/content")
      .then((response) => {
        const { data } = response;
        setData(data);
      })
      .catch((e) => {
        setData([]);
      });
  };

  useEffect(() => {
    dispatch(setPath("/home"));
  }, [dispatch]);

  useEffect(() => {
    _getData();
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainContainer>
      <Helmet>
        <title>Baring Digital</title>
      </Helmet>
      <ColumnOne>
        {data.map((item, index) => (
          <div className="mb-5" key={`${index}`}>
            <Header>
              {item.name || (
                <div className="w-1/3">
                  <Skeleton />
                </div>
              )}
            </Header>
            <div className="grid grid-flow-row grid-cols-2 md:grid-cols-3 gap-5 lg:gap-8 mt-8 pb-8 box-border">
              {item.contents?.map((item, index) => (
                <ContentList
                  cover={item.cover}
                  title={item.title}
                  bab={item.babs_count}
                  authors={item.authors}
                  key={index}
                  id={item.id}
                  isLiked={
                    item.is_liked && !tempUnliked.includes(item.id)
                      ? true
                      : false || tempLiked.includes(item.id)
                      ? true
                      : false
                  }
                  onLike={() => {
                    setTempLiked((value) => value.concat([item.id]));
                    if (tempUnliked.includes(item.id)) {
                      setTempUnliked((value) => {
                        const temp = value;
                        const index = temp.indexOf(item.id);
                        temp.splice(index, 1);
                        return [...temp];
                      });
                    }
                  }}
                  onUnlike={() => {
                    setTempUnliked((value) => value.concat([item.id]));
                    if (tempLiked.includes(item.id)) {
                      setTempLiked((value) => {
                        const temp = value;
                        const index = temp.indexOf(item.id);
                        temp.splice(index, 1);
                        return [...temp];
                      });
                    }
                  }}
                />
              ))}
            </div>
            <div className="flex justify-end mb-10">
              <Button link={true} to={`/category/${item.id}`}>
                Lebih Banyak
              </Button>
            </div>
          </div>
        ))}
      </ColumnOne>
      <ColumnTwo></ColumnTwo>
    </MainContainer>
  );
}
