import Skeleton from "react-loading-skeleton";
import NumberFormat from "react-number-format";
export default function PackageOption({ title, subtitle, price, onClick }) {
  return (
    <button
      className={
        "border p-3 lg:p-5 hover:bg-white-200 dark:hover:bg-dark-200 block w-full text-left border-white-300 dark:border-black-100 mb-5"
      }
      type="button"
      onClick={onClick}
    >
      <div className="text-black-100 dark:text-white-400 poppins text-lg font-bold">
        {title || <Skeleton />}
      </div>
      <div className="text-black-200 dark:text-white-500 roboto border-b border-white-300 dark:border-black-100 pb-3 lg:pb-5">
        {subtitle || <Skeleton />}
      </div>
      <div className="roboto text-black-200 dark:text-white-500 font-bold mt-3 lg:mt-5 text-right">
        {price ? (
          <NumberFormat
            decimalScale={0}
            thousandSeparator={true}
            prefix="Rp"
            value={price}
            displayType="text"
          />
        ) : (
          <Skeleton />
        )}
      </div>
    </button>
  );
}
