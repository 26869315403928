import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import Header, { Heading } from "../components/AuthPages/Header";
import InnerContainer from "../components/AuthPages/InnerContainer";
import Button from "../components/Button";
import CustomAlert from "../components/CustomAlert";
import Loader from "../components/Loader";
import TextInput from "../components/TextInput";
import service from "../service";

export default function ResetPassword() {
  const [success, setSuccess] = useState(false);
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      password: "",
      token: "",
    },
  });
  const _loadRef = useRef();
  const { search } = useLocation();

  const _proceed = ({ password, token }) => {
    _loadRef.current.show();
    service
      .post("/auth/user/reset-password", {
        password,
        token,
      })
      .then((response) => {
        setSuccess(true);
      })
      .catch((e) => {
        _loadRef.current.hide();
      });
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    setValue("token", query.get("token") || "");
  }, [search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Lupa Password</title>
      </Helmet>
      <div className="bg-white-200 dark:bg-dark-200 min-h-screen w-full flex justify-center items-center">
        <InnerContainer>
          <Header />
          {success ? (
            <Fragment>
              <CustomAlert success={true}>
                Password anda telah berhasil diatur ulang, silahkan lanjutkan
                login menggunakan password baru
              </CustomAlert>
              <Button link={true} to="/" className="flex w-full mt-5">
                Lanjutkan Login
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Heading
                scheme="black"
                title="Ubah Password"
                subtitle="Atur ulang password anda"
              />
              <form className="mt-8" onSubmit={handleSubmit(_proceed)}>
                <TextInput
                  type="password"
                  placeholder="Password Baru"
                  containerClassName="mb-5"
                  {...register("password", { required: true })}
                />
                <Button type="submit" className="flex w-full">
                  <Loader
                    className="bg-black-100"
                    placeholder="Lanjutkan"
                    ref={_loadRef}
                  />
                </Button>
              </form>
            </Fragment>
          )}
        </InnerContainer>
      </div>
    </Fragment>
  );
}
