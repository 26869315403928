import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Container from "../components/AuthPages/Container";
import Button from "../components/Button";
import CustomAlert from "../components/CustomAlert";
import Loader from "../components/Loader";
import TextInput from "../components/TextInput";
import Logo from "../images/Logo.png";
import service from "../service";

export default function Login() {
  const _loadRef = useRef();
  const [error, setError] = useState(false);
  const { handleSubmit, register } = useForm();
  const setCookies = useCookies()[1];

  const _proceedLogin = ({ username, password }) => {
    _loadRef.current.show();
    service
      .get("/auth/user/get-token", {
        params: {
          username,
          password,
        },
      })
      .then((response) => {
        const { subscriber, token, id, partner_id: partnerId } = response.data;

        setCookies("id", id, {
          path: "/",
          expires: moment().add(10, "years").toDate(),
        });
        if (subscriber) {
          setCookies("subscriber", "true", {
            path: "/",
            expires: moment().add(10, "years").toDate(),
          });
        }
        setCookies("token", token, {
          path: "/",
          expires: moment().add(10, "years").toDate(),
        });

        if (partnerId) {
          setCookies(
            "logo",
            `${process.env.REACT_APP_BASE_URL}/api/stream/partner-logo/${partnerId}`,
            {
              path: "/",
              expires: moment().add(10, "years").toDate(),
            }
          );
        } else {
          setCookies(
            "logo",
            `${process.env.REACT_APP_BASE_URL}/base-logo.png`,
            {
              path: "/",
              expires: moment().add(10, "years").toDate(),
            }
          );
        }
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          _loadRef.current.hide();
          setError(true);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Container>
        <div className="bg-white-100 dark:bg-dark-100 w-full m-5 md:w-1/2 lg:w-2/3 xl:w-3/5 flex flex-row">
          <div className="bg-primary-100 hidden lg:flex w-auto md:w-1/2 mr-0 lg:-mr-1 justify-center items-center">
            <img src={Logo} className="w-64" alt="Logo Baring Digital" />
          </div>
          <div className="p-5 py-8 lg:p-8 box-border flex-1">
            <div className="pl-5 lg:pl-8 -ml-5 lg:-ml-8 border-l-0 lg:border-l-4 border-secondary-100">
              <div className="text-2xl poppins font-bold text-primary-100 dark:text-white-400">
                Login
              </div>
              <div className="roboto text-black-200 dark:text-white-500">
                Masuk ke akun baring anda untuk memulai.
              </div>
            </div>
            <form
              className="mt-8 lg:mt-12"
              onSubmit={handleSubmit(_proceedLogin)}
            >
              {error && (
                <CustomAlert className="mb-4 text-center">
                  Username atau password salah
                </CustomAlert>
              )}
              <TextInput
                containerClassName="mb-4"
                placeholder="Username"
                type="text"
                {...register("username", { required: true })}
              />
              <TextInput
                containerClassName="mb-4"
                placeholder="Password"
                type="password"
                {...register("password", { required: true })}
              />
              <div className="flex justify-between items-center">
                <Link
                  to="/auth/forgot-password"
                  className="font-bold roboto text-black-100 dark:text-white-400"
                >
                  Lupa Password?
                </Link>
                <Button
                  type="submit"
                  className="w-1/3"
                  disabled={_loadRef.current?.status() || false}
                >
                  <Loader
                    className="bg-black-100"
                    placeholder="Masuk"
                    ref={_loadRef}
                  />
                </Button>
              </div>
            </form>
            <div className="h-5 border-t border-white-200 dark:border-dark-200 mt-5" />
            <div className="text-center roboto text-black-200 dark:text-white-500">
              Tidak mempunyai akun? <br />
              <Link
                to="/auth/register"
                className="font-bold text-black-100 dark:text-white-400"
              >
                Buat Akun
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  );
}
